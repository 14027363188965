import AddressSubform from './AddressSubform';
import { NamespacedSubform } from './types';

const physicalAddressLabels = {
  company: 'Legal Business Name (optional)',
  address1: 'Physical Street Address',
};

type PhysicalAddressSubformProps<NS extends string> = {
  carrier: 'ups' | 'usps';
} & NamespacedSubform<NS>;

export default function PhysicalAddressSubform<NS extends string>({
  namespace,
  carrier,
}: PhysicalAddressSubformProps<NS>) {
  return (
    <AddressSubform<NS>
      namespace={namespace}
      customLabels={physicalAddressLabels}
      nameRequired
      phone={carrier === 'usps' ? 'US' : undefined}
    />
  );
}
