import styled from '@emotion/styled';
import { LabelHTMLAttributes, ReactNode, useId } from 'react';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import Icon from '../Icon';
import SecondaryText from './SecondaryText';
import Tooltip from './Tooltip';

export const StyledLabel = styled.label<LabelProps>`
  display: inline-block;
  padding: ${SPACING.sm} 0;
  font-size: ${TYPOGRAPHY.body.fontSize};
  font-weight: ${TYPOGRAPHY.fontWeight.medium};

  color: ${GREYSCALE.grey80};

  input[type='checkbox'],
  input[type='radio'] {
    margin-right: ${SPACING.sm};
  }
`;

const Styled = {
  TooltipIcon: styled(Icon)`
    min-width: ${SPACING.lg};
    height: ${SPACING.lg};
    margin-left: ${SPACING.xs};
    font-size: ${TYPOGRAPHY.fontSize.xs};
    color: ${GREYSCALE.grey30};
    cursor: pointer;
  `,
};

export type LabelProps = {
  secondary?: ReactNode;
  tooltip?: string;
} & LabelHTMLAttributes<HTMLLabelElement>;

export default function Label({ children, secondary, tooltip, ...others }: LabelProps) {
  const tooltipId = useId();

  return (
    <StyledLabel {...others}>
      {children}
      {secondary && (
        <>
          {' '}
          <SecondaryText>{secondary}</SecondaryText>
        </>
      )}
      {tooltip && (
        <>
          <Styled.TooltipIcon id={tooltipId} icon="circle-question" />
          <Tooltip anchorSelector={`#${CSS.escape(tooltipId)}`}>{tooltip}</Tooltip>
        </>
      )}
    </StyledLabel>
  );
}
