import { AddressSubformValues } from '../../../subforms/AddressSubform';
import { INITIAL_ADDRESS_SUBFORM_VALUES } from '../../../subforms/addressSubformUtils';

export type SignupFormUser = {
  email: string;
  password: string;
  agree: boolean;
};

export type SignupFormAddresses = {
  useOriginAsReturnAddress: boolean;
  originAddress: AddressSubformValues;
  returnAddress: AddressSubformValues;
};

export type SignupFormValues = SignupFormUser & SignupFormAddresses;

export const initialValues: SignupFormValues = {
  email: '',
  password: '',
  useOriginAsReturnAddress: true,
  originAddress: INITIAL_ADDRESS_SUBFORM_VALUES,
  returnAddress: (({ phone, ...rest }) => rest)(INITIAL_ADDRESS_SUBFORM_VALUES), // returnAddress should not have a phone number
  agree: false,
};
