import { BATCH_REFUND_COPY } from '../constants/refundSuccessMessages';

export default function getBatchRefundModalTitle(
  canInstantRefundBatch: boolean,
  isUpsOnlyBatch: boolean,
  isUspsOnlyBatch: boolean,
) {
  if (canInstantRefundBatch) {
    return BATCH_REFUND_COPY.instant.title;
  }
  if (isUpsOnlyBatch) {
    return BATCH_REFUND_COPY.ups.title;
  }
  if (isUspsOnlyBatch) {
    return BATCH_REFUND_COPY.usps.title;
  }
  return BATCH_REFUND_COPY.default.title;
}
