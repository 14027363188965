import { SHIPMENT_REFUND_COPY } from '../constants/refundSuccessMessages';

function getShipmentRefundSuccessMessage(
  canInstantRefundShipment: boolean,
  isUpsShipment: boolean,
) {
  if (canInstantRefundShipment) {
    return SHIPMENT_REFUND_COPY.instant.success;
  }
  if (isUpsShipment) {
    return SHIPMENT_REFUND_COPY.ups.success;
  }

  return SHIPMENT_REFUND_COPY.usps.success;
}

export default getShipmentRefundSuccessMessage;
