import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const batchUploadFormQuery = gql(/* GraphQL */ `
  query BatchUploadFormQuery($id: ID!) {
    batch(id: $id) {
      id
      title
      step
      customsFormRequired
      hsCodeRequired
      upload {
        id
      }
    }
    company {
      id
      activeCarriers
      settings {
        defaultWarehouseId
        defaultShipmentPresetId
      }
      warehouses {
        ...WarehouseFragment
      }
      shipmentPresets {
        ...ShipmentPresetFragment
      }
    }
    shipmentBoundaries {
      maxWeight
      maxCombinedLength
      maxLengthPlusGirth
      minLongSide
      maxLongSide
      minMiddleSide
      maxMiddleSide
      minShortSide
      maxShortSide
    }
    carriers {
      id
      carrierKey
      packageTypes {
        id
        packageTypeKey
        title
        description
        heightRequired
        weightRequired
        dimensionsRequired
      }
    }
    countries {
      id
      countryCode
      name
    }
  }
`);

export const useBatchUploadFormQuery = () => useQuery(batchUploadFormQuery);
