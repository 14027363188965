import { gql } from '../../gql';

const updateEmailNotificationMutation = gql(/* GraphQL */ `
  mutation updateEmailNotification(
    $batchId: ID!
    $mailTemplateId: ID!
    $scheduledDateTime: String!
  ) {
    updateEmailNotification(
      batchId: $batchId
      mailTemplateId: $mailTemplateId
      scheduledDateTime: $scheduledDateTime
    ) {
      id
      notifyRecipientsDate
      emailNotificationTemplate {
        id
      }
    }
  }
`);

export default updateEmailNotificationMutation;
