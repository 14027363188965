import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';
import { TYPOGRAPHY } from '../../styles/typography';

const Styled = {
  SecondaryText: styled.small`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    color: ${GREYSCALE.grey50};
  `,
};

function SecondaryText({ children }: React.PropsWithChildren) {
  return <Styled.SecondaryText>{children}</Styled.SecondaryText>;
}

export default SecondaryText;
