import { useReactiveVar } from '@apollo/client';
import Modal from '../../components/modals/Modal';
import useWindowSize from '../../hooks/useWindowSize';
import FlashMessages from '../../components/FlashMessages';
import { flashMessagesVar } from '../../apollo/cache';
import { clearFlashMessages } from '../../apollo/cache/flashMessages';
import { useDownloadLabelsByBatchQuery } from '../operations/downloadLabelsByBatch.query';
import { Container, Row, Col } from '../../components/layout/Grid';
import PageLoading from '../../components/loading/PageLoading';
import PageLayoutSelect from './PageLayoutSelect';
import ProgressButton from '../../components/form/ProgressButton';
import ShareLink from './ShareLink';
import downloadUrl from '../utils/downloadUrl';
import { useCreateScanformByBatchMutation } from '../operations/createScanformByBatch.mutation';
import { useShareDownloadUrlByBatchLazyQuery } from '../operations/shareDownloadUrlByBatch.query';
import ScanformButton from './ScanformButton';
import PdfPreview from './PdfPreview';

type BatchPrintModalProps = {
  open: boolean;
  onClose: () => void;
  batchId: string;
  canCreateScanform: boolean;
  scanformUrl: string | null;
};

export default function BatchPrintModal({
  open,
  onClose,
  batchId,
  canCreateScanform,
  scanformUrl,
}: BatchPrintModalProps) {
  const [, windowHeight] = useWindowSize();
  const [createScanfrom, { loading: creatingScanfrom }] = useCreateScanformByBatchMutation();
  const [createShareLink, { data: shareLinkData }] = useShareDownloadUrlByBatchLazyQuery({
    variables: { batchId },
  });
  const { data, loading, refetch, startPolling, stopPolling } = useDownloadLabelsByBatchQuery({
    variables: { batchId },
    skip: !open, // Run query once the modal is open
    notifyOnNetworkStatusChange: true, // Call onCompleted after each fetch
    onError: () => {
      stopPolling();
    },
    onCompleted: ({ downloadLabelsByBatch: { status } }) => {
      switch (status) {
        case 'IDLE':
        case 'RUNNING':
          startPolling(1000);
          break;
        case 'ERROR':
        case 'FINISHED':
          stopPolling();
          break;
        default:
      }
    },
  });

  const flashMessages = useReactiveVar(flashMessagesVar);

  const onModalClose = () => {
    clearFlashMessages();
    onClose?.();
  };

  const renderPreview = () => {
    if (flashMessages.length) {
      return null; // TODO: render flash messages
    }
    if (
      !loading &&
      // correct status to show the print preview
      data !== undefined &&
      data.downloadLabelsByBatch.pageLayout &&
      data.downloadLabelsByBatch.fileFormat &&
      data.downloadLabelsByBatch.url &&
      data.downloadLabelsByBatch.status === 'FINISHED'
    ) {
      const { pageLayout, fileFormat, url } = data.downloadLabelsByBatch;

      return (
        <>
          <Row>
            <Col md={12}>
              <PageLayoutSelect
                pageLayout={pageLayout}
                fileFormat={fileFormat}
                onPageLayoutChange={(newPageLayout) => {
                  refetch({
                    batchId,
                    pageLayout: newPageLayout,
                  });
                }}
              />
            </Col>
            <PdfPreview shipmentLabelFileFormat={fileFormat} url={url} />
          </Row>
          <Row>
            <Col xs={12} sm={6} spaceBelow>
              <ScanformButton
                canCreateScanform={canCreateScanform}
                scanformUrl={scanformUrl}
                loading={creatingScanfrom}
                onCreateScanfrorm={() => {
                  createScanfrom({
                    variables: {
                      batchId,
                    },
                  });
                }}
              />
            </Col>
            <Col xs={12} sm={canCreateScanform || scanformUrl ? 6 : 12} spaceBelow>
              <ProgressButton
                variant="success"
                fullWidth
                data-testid="download-label-button"
                disabled={false}
                progress={false}
                onClick={(event) => {
                  event.stopPropagation();
                  downloadUrl(url);
                }}
              >
                Download Labels
              </ProgressButton>
            </Col>

            <Col xl={12}>
              <ShareLink
                onClick={() => createShareLink()}
                url={shareLinkData?.shareDownloadUrlByBatch}
              />
            </Col>
          </Row>
        </>
      );
    }
    return <PageLoading height={`${windowHeight / 2}px`} color="white" />;
  };

  return (
    <Modal theme="dark" open={open} width={950} onClose={onModalClose}>
      <Container noGutter>
        <Row>
          <Col md={12}>
            <FlashMessages />
          </Col>
        </Row>
        {renderPreview()}
      </Container>
    </Modal>
  );
}
