import { gql } from '../../gql';

const cancelEmailNotificationMutation = gql(/* GraphQL */ `
  mutation cancelEmailNotification($batchId: ID!) {
    cancelEmailNotification(id: $batchId) {
      id
      notifyRecipientsDate
      emailNotificationTemplate {
        id
      }
    }
  }
`);

export default cancelEmailNotificationMutation;
