import { useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Row as DataGridRow } from '@tanstack/react-table';
import { BatchPrintModal } from '@ps/printLabels';
import {
  BatchRefundModal,
  getShipmentRefundSuccessMessage,
  refundBatchMutation,
  refundShipmentMutation,
  ShipmentRefundModal,
} from '@ps/refundLabels';
import batchDetailsPageQuery from '../operations/batchDetailsPage.query';
import { Col, PageContainer, Row } from '../../components/layout/Grid';
import ShipmentDetailsBox from '../components/BatchDetailsPageShipmentDetailsBox';
import BatchDetailsPagePrintButton from '../components/BatchDetailsPagePrintButton';
import PageLoading from '../../components/loading/PageLoading';
import BatchTitle from '../../components/form/BatchTitle';
import { useUpdateBatchTitleMutation } from '../../operations/mutations/updateBatchTitle';
import { GREYSCALE } from '../../styles/colors';
import { DATE_FORMAT } from '../../constants';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import ServiceSummaryTable from '../components/BatchServiceSummaryTable';
import ConnectedDataGrid from '../../components/dataGrid/ConnectedDataGrid';
import useNavigateOrHref from '../../hooks/useNavigateOrHref';
import useLogger from '../../hooks/useLogger';
import ProgressButton from '../../components/form/ProgressButton';
import { DataGridImperativeHandleRef } from '../../components/dataGrid/DataGrid';
import PageHeader from '../../components/layout/PageHeader';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import isSingleCarrierBatch from '../../utils/isSingleCarrierBatch';
import { useFailedShipmentsExportLazyQuery } from '../../operations/queries/failedShipmentsExport';
import useMappedCountries from '../../hooks/useMappedCountries';
import triggerGoogleTagManagerCustomEvent from '../../utils/triggerGoogleTagManagerCustomEvent';

type BatchDetailsPageProps = {
  bridgeBatchId?: string; // only used if we are coming from the bridge
};

const Styled = {
  TransactionDateText: styled.div`
    color: ${GREYSCALE.grey50};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    padding-bottom: ${SPACING.sm};
  `,
  TopButtons: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${SPACING.md};
  `,
};

export default function BatchDetailsPage({ bridgeBatchId }: BatchDetailsPageProps) {
  const params = useParams();
  const logger = useLogger();
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);
  const [isExporting, setIsExporting] = useState(false);
  const navigateOrHref = useNavigateOrHref();
  const batchId = params.batchId || bridgeBatchId || '0'; // the 0 should never happen
  const [refundBatchModalOpen, setRefundBatchModalOpen] = useState(false);
  const [selectedShipmentIdForRefund, setSelectedShipmentIdForRefund] = useState<string | false>(
    false,
  ); // if there is a shipmentId set for refund, the modal will open.
  const [updateBatchTitle] = useUpdateBatchTitleMutation();
  const { formatDate } = useDateInUserTimezone();
  const [showBatchPrintModal, setShowBatchPrintModal] = useState(false);
  const { data, loading } = useQuery(batchDetailsPageQuery, {
    variables: { batchId },
  });
  const countryMap = useMappedCountries();
  const [getFailedShipmentsExportStatus] = useFailedShipmentsExportLazyQuery();
  const [refundBatch, { loading: refundingBatch }] = useMutation(refundBatchMutation);
  const [refundShipment, { loading: refundingShipment }] = useMutation(refundShipmentMutation);
  const { isUspsOnlyBatch, isUpsOnlyBatch } = isSingleCarrierBatch(data?.batch);

  const performShipmentRefund = async () => {
    if (selectedShipmentIdForRefund === false) {
      return;
    }
    try {
      const result = await refundShipment({
        variables: {
          shipmentId: selectedShipmentIdForRefund,
        },
      });
      if (result.errors) {
        const combinedError = result.errors.map((error) => error.message).join('; ');
        throw new Error(combinedError);
      }
    } catch (error) {
      logger.info('Refund errors on batch', { id: selectedShipmentIdForRefund }, error as Error);
      return;
    } finally {
      imperativeHandleRef.current?.refetchData();
      const shipment = data?.batch.shipments.find((s) => s.id === selectedShipmentIdForRefund);
      setFlashMessage(
        getShipmentRefundSuccessMessage(
          shipment?.canInstantRefundShipment ?? false,
          shipment?.carrierKey === 'ups',
        ),
        'success',
      );
      setSelectedShipmentIdForRefund(false); // this closes the modal
    }
  };

  const performBatchRefund = async (id: string) => {
    try {
      const result = await refundBatch({
        variables: {
          id,
        },
      });
      if (result.errors) {
        const combinedError = result.errors.map((error) => error.message).join('; ');
        throw new Error(combinedError);
      }
    } catch (error) {
      logger.info('Refund errors on batch', { id }, error as Error);
      return;
    } finally {
      setRefundBatchModalOpen(false);
      navigateOrHref(`/batch/refunding/${id}`, `/batch/refunding/${id}`);
    }
  };

  if (loading || !data) {
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );
  }

  const { batch } = data;

  const makeLinkToShipment = (row: DataGridRow<any>) => {
    const shipmentId = row.getAllCells()[0].row.original.id;
    return `/batch/${batchId}/shipment/${shipmentId}`;
  };

  return (
    <PageContainer>
      <BatchPrintModal
        open={showBatchPrintModal}
        batchId={batchId}
        canCreateScanform={batch.canCreateScanform}
        scanformUrl={batch.scanformUrl}
        onClose={() => setShowBatchPrintModal(false)}
      />
      <ShipmentRefundModal
        open={selectedShipmentIdForRefund !== false}
        onCancel={() => setSelectedShipmentIdForRefund(false)}
        isUpsShipment={
          batch.shipments.find((s) => s.id === selectedShipmentIdForRefund)?.carrierKey === 'ups'
        }
        canInstantRefundShipment={
          batch.shipments.find((s) => s.id === selectedShipmentIdForRefund)
            ?.canInstantRefundShipment ?? false
        }
        onConfirm={async () => {
          performShipmentRefund();
        }}
        confirmationButtonProgress={refundingShipment}
      />
      <BatchRefundModal
        open={refundBatchModalOpen}
        canInstantRefundBatch={batch.canInstantRefundBatch}
        isUspsOnlyBatch={isUspsOnlyBatch}
        isUpsOnlyBatch={isUpsOnlyBatch}
        onCancel={() => setRefundBatchModalOpen(false)}
        onConfirm={async () => {
          performBatchRefund(batch.id);
        }}
        confirmationButtonProgress={refundingBatch}
      />
      <Row>
        <Col>
          <PageHeader
            noPaddingBelow={batch.canPrint} // special case, because the big green button sometimes exists in the right side actions
            actionsTopOnMobile
            title={
              <div>
                <Styled.TransactionDateText>
                  {formatDate(
                    'UTC',
                    batch.createdAt,
                    `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero} ${DATE_FORMAT.timezone}`,
                  )}
                </Styled.TransactionDateText>
                <BatchTitle
                  value={batch.title}
                  onUpdate={(title) => {
                    updateBatchTitle({
                      variables: {
                        id: batch.id,
                        title,
                      },
                      optimisticResponse: {
                        updateBatchTitle: {
                          __typename: 'Batch',
                          id: batch.id,
                          title,
                        },
                      },
                    });
                  }}
                />
              </div>
            }
            rightSideActions={[
              <BatchDetailsPagePrintButton
                key="print"
                batch={batch}
                batchLoading={loading}
                onClick={() => {
                  setShowBatchPrintModal(true);
                  triggerGoogleTagManagerCustomEvent('Click Print Label');
                }}
              />,
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ShipmentDetailsBox batch={batch} countryMap={countryMap} />
        </Col>
      </Row>
      <Row>
        <Col md={12} spaceBelow>
          <ServiceSummaryTable
            batch={batch}
            onClickFailedShipmentsExport={getFailedShipmentsExportStatus}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} spaceBelow>
          <ConnectedDataGrid
            imperativeHandleRef={imperativeHandleRef}
            queryName="batch:trackingDataGrid"
            gridQueryVariables={[
              { variableName: 'id', type: 'ID!', value: batch.id, forField: 'batch' },
            ]}
            shouldExportAllColumns
            searchBarLabel="Search"
            onRowClick={makeLinkToShipment}
            rowActionHandlers={{
              'refund-action': (row) => {
                setSelectedShipmentIdForRefund(row.original.id);
              },
            }}
            rowActionLinkMakers={{
              'view-action': makeLinkToShipment,
            }}
            rightSideSlot={[
              <Col sm={12} md={batch.canRefund ? 6 : 3} key="actionButtons">
                <Row>
                  <Col xs={12} sm={batch.canRefund ? 6 : 12} spaceBelow>
                    <ProgressButton
                      data-testid="export-button"
                      fullWidth
                      size="medium"
                      disabled={refundingBatch || refundingShipment || isExporting}
                      progress={refundingBatch || refundingShipment || isExporting}
                      variant="info"
                      onClick={async () => {
                        setIsExporting(true);
                        await imperativeHandleRef.current?.exportData();
                        setIsExporting(false);
                      }}
                    >
                      Export Tracking Data
                    </ProgressButton>
                  </Col>
                  {batch.canRefund && (
                    <Col xs={12} sm={6} spaceBelow>
                      <ProgressButton
                        data-testid="refund-button"
                        fullWidth
                        size="medium"
                        disabled={refundingBatch || refundingShipment || isExporting}
                        progress={refundingBatch || refundingShipment || isExporting}
                        variant="danger"
                        onClick={() => setRefundBatchModalOpen(true)}
                      >
                        {`Refund ${
                          batch.shipmentStatusSummary.refundableCount > 1 ? 'Labels' : 'Label'
                        } (${batch.shipmentStatusSummary.refundableCount})`}
                      </ProgressButton>
                    </Col>
                  )}
                </Row>
              </Col>,
            ]}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}
