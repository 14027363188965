import { lazy, useMemo, useRef, useState } from 'react';
import { Hidden } from 'react-grid-system';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import useBus from 'use-bus';
import {
  EXTERNAL_SUPPORT_URL,
  LOGO_ANIMATION_DO_NOT_REPEAT_LAST_X,
  LOGO_ANIMATION_INTERVAL,
  LOGO_ANIMATION_INTERVAL_JITTER,
  SIDEBAR_COLLAPSIBLE_DURATION,
  SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION,
  SIDEBAR_MENU_ITEM_HEIGHT,
  SIDEBAR_WIDTH,
  UiEvents,
} from '../../../constants';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import BurgerIcon from '../BurgerIcon';
import { GREYSCALE } from '../../../styles/colors';
import Z_INDEX from '../../../styles/zindex';
import { TYPOGRAPHY } from '../../../styles/typography';
import { BORDER_WIDTH } from '../../../styles/borders';
import SidebarMenuItem, { StyledMenuIconWrapper, StyledMenuLabel } from './SidebarMenuItem';
import Link, { LinkButton } from '../../Link';
import { SPACING } from '../../../styles/spacing';
import { OPACITY } from '../../../styles/opacities';
import SidebarDrawer from './SidebarDrawer';
import SidebarApplicationInformation from './SidebarApplicationInformation';
import { PlatformKey, platformKeyToIconUrl } from '../../../assets/platformIcons';
import { LogoAnimation, LogoRef } from './Logo';
import blockForwardingProps from '../../../utils/blockForwardingProps';
import useIsMobile from '../../../hooks/useIsMobile';
import useModal from '../../../hooks/useModal';
import PrivacyOptionsIcon from '../../../assets/miscIcons/privacyoptions.svg';
import linkStyles from '../../linkStyles';
import useLogout from '../../../hooks/useLogout';
import environment from '../../../utils/environment';
import navigation from '../../../utils/navigation';

const RandomAnimationLogo = lazy(() => import('./RandomAnimationLogo'));
const Icon = lazy(() => import('../../Icon'));

function getRandomPurchaseAnimation(): LogoAnimation {
  const purchaseAnimations: LogoAnimation[] = [];
  const now = new Date();

  /**
   * Include animations based on the current date. Please note that month in the Date constructor
   * is 0 based, so January is 0 and December is 11.
   */

  // The following animations are Independence-Day-themed
  const independenceDayStart = new Date(now.getFullYear(), 6, 4); // July 4th
  const independenceDayEnd = new Date(now.getFullYear(), 6, 10); // July 10th (exclusive)
  if (now >= independenceDayStart && now < independenceDayEnd) {
    purchaseAnimations.push('July4th');
  }

  // The following animations are Halloween-themed
  const halloweenStart = new Date(now.getFullYear(), 9, 25); // October 25th
  const halloweenEnd = new Date(now.getFullYear(), 10, 2); // November 2nd (exclusive)
  if (now >= halloweenStart && now < halloweenEnd) {
    purchaseAnimations.push('JackOLantern', 'Dracula', 'Ghost');
  }

  // The following animations are Christmas-themed
  const christmasStart = new Date(now.getFullYear(), 11, 11); // December 11th
  const christmasEnd = new Date(now.getFullYear(), 11, 26); // December 26th (exclusive)
  if (now >= christmasStart && now < christmasEnd) {
    purchaseAnimations.push('Present');
  }

  // The following animations are generic celebrations
  if (purchaseAnimations.length === 0) {
    purchaseAnimations.push('PartyCelebration', 'Starfish', 'HatFlip', 'Hearts');
  }

  return purchaseAnimations[Math.floor(Math.random() * purchaseAnimations.length)];
}

const randomAnimations: LogoAnimation[] = [
  'Smile',
  'SmileBeard',
  'SmileHat',
  'Bubble',
  'Twinkle',
  'Cry',
  'LaughTears',
  'Satisfied',
];

const sadAnimations: LogoAnimation[] = ['Cry'];

const Styled = {
  SidebarWrapper: styled.div<{
    collapsed: boolean;
    willChangeWidth: boolean;
  }>`
    position: relative;
    z-index: ${Z_INDEX.sidebar};

    background-color: ${GREYSCALE.grey10};

    min-height: 100vh;

    padding-left: env(safe-area-inset-left);
    width: ${({ collapsed }) => (collapsed ? SIDEBAR_WIDTH.collapsed : SIDEBAR_WIDTH.expanded)};
    transition: width ${SIDEBAR_COLLAPSIBLE_DURATION} ${SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION};
    will-change: ${({ willChangeWidth }) => (willChangeWidth ? 'contents' : 'auto')};

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      transform: translateY(0px);
      width: 100% !important;
      min-height: initial;
    }

    /* animate opacity of menu labels */

    ${StyledMenuLabel} {
      transition: opacity ${SIDEBAR_COLLAPSIBLE_DURATION} ${SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION};
      opacity: 1;
    }

    ${({ collapsed }) =>
      collapsed &&
      css`
        ${StyledMenuLabel} {
          opacity: 0;
          @media (max-width: ${MEDIA_QUERY.xsMax}) {
            opacity: 1;
          }
        }
      `}

    /* animate position of menu icons */

    ${StyledMenuIconWrapper} {
      transition: transform ${SIDEBAR_COLLAPSIBLE_DURATION} ${SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION};
      transform: translateX(0);
    }

    ${({ collapsed }) =>
      collapsed &&
      css`
        ${StyledMenuIconWrapper} {
          transform: translateX(-4px); // shift items when collapsed to stay centered
        }
      `}

    @media print {
      display: none;
    }
  `,
  MenuBar: styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: env(safe-area-inset-left);
    margin-left: calc(-1 * env(safe-area-inset-left));
    background-color: ${GREYSCALE.black};
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      height: auto;
    }
  `,
  LogoLink: styled(Link, blockForwardingProps('collapsed'))<{
    collapsed: boolean;
  }>`
    width: ${({ collapsed }) => (collapsed ? SIDEBAR_WIDTH.collapsed : SIDEBAR_WIDTH.expanded)};
    height: ${({ collapsed }) => (collapsed ? SIDEBAR_WIDTH.collapsed : SIDEBAR_WIDTH.expanded)};
    transform-origin: bottom center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width ${SIDEBAR_COLLAPSIBLE_DURATION} ${SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION},
      height ${SIDEBAR_COLLAPSIBLE_DURATION} ${SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION};
    &:focus-within {
      z-index: 1; /* fix focus ring visibility */
    }

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      height: ${SIDEBAR_MENU_ITEM_HEIGHT};
      width: ${SIDEBAR_MENU_ITEM_HEIGHT};
      padding-left: 8px;
    }
  `,
  BurgerIconWrapper: styled(LinkButton)`
    display: none;
    background: none;
    border: ${BORDER_WIDTH.none};
    padding: 0;
    cursor: pointer;

    &:focus-within {
      z-index: 1; /* fix focus ring visibility */
    }

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      display: block;
    }
  `,
  Menu: styled.nav<{ open: boolean }>`
    background-color: ${GREYSCALE.grey10};
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      display: ${({ open }) => (open ? 'block' : 'none')};
    }
  `,
  CollapseButton: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: ${TYPOGRAPHY.fontSize.sm};
    color: ${GREYSCALE.grey50};
    border: ${BORDER_WIDTH.none};
    background: none;
    padding: ${SPACING.md};
    margin: ${SPACING.lg} auto;
    opacity: ${OPACITY.half};

    &:hover {
      color: ${GREYSCALE.black};
    }
  `,
  CollapseButtonText: styled.span`
    padding-left: ${SPACING.sm};
  `,
  ApplicationInformationWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${TYPOGRAPHY.fontSize.xxs};
    color: ${GREYSCALE.grey50};
  `,
  ApplicationInformation: styled.span`
    text-align: center;
    padding: ${SPACING.xs};
  `,
  Footer: styled.footer`
    text-align: center;
    font-size: ${TYPOGRAPHY.fontSize.xxs};
    color: ${GREYSCALE.grey50};

    a {
      ${linkStyles};
      color: inherit;
      text-decoration: underline;
    }
  `,
  FooterLink: styled(LinkButton)`
    color: inherit;
    text-decoration: underline;
  `,
  FooterLinksContainer: styled.div`
    padding: ${SPACING.none} ${SPACING.md};
    margin: ${SPACING.xl} ${SPACING.none};
  `,
  FooterAdminBarToggle: styled.button`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: ${BORDER_WIDTH.none};
    outline: none;
    background-color: transparent;
    margin: ${SPACING.md} auto;
    font-size: ${TYPOGRAPHY.fontSize.xl};
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: ${OPACITY.half};
    &:hover {
      opacity: 1;
    }
    &:hover span {
      color: ${GREYSCALE.grey50};
    }
  `,
  FooterAdminBarToggleText: styled.span`
    color: transparent;
    font-size: ${TYPOGRAPHY.fontSize.xs};
    &:hover {
      color: ${GREYSCALE.grey50};
    }
  `,
  FooterIcon: styled.img`
    height: ${TYPOGRAPHY.fontSize.xxs};
    margin-right: ${SPACING.xs};
    vertical-align: middle;
  `,
};

export type SidebarProps = {
  currentUserRoles: ReadonlyArray<string>;
  activePlatformKeys: ReadonlyArray<string>;
  onToggleSidebar: () => void;
  isSidebarCollapsed: boolean;
  onToggleAdminBar: () => void;
};

export default function Sidebar({
  currentUserRoles,
  activePlatformKeys,
  onToggleSidebar,
  isSidebarCollapsed,
  onToggleAdminBar,
}: SidebarProps) {
  const { isMobile } = useIsMobile();
  const [open, setOpen] = useState(false);
  const logoRef = useRef<LogoRef>(null);
  const shipMenuItemRef = useRef<HTMLDivElement>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [willChangeWidth, setWillChangeWidth] = useState(false);
  const platformIconUrl =
    activePlatformKeys.length === 1
      ? platformKeyToIconUrl.get(activePlatformKeys[0] as PlatformKey)
      : undefined;
  const platformLink = activePlatformKeys.length >= 1 ? '/import' : '/settings/integrations';
  const isSupportAgent = useMemo(() => currentUserRoles.includes('support'), [currentUserRoles]);
  const setModal = useModal();
  const logout = useLogout();

  // Trigger animation when batch was purchased
  useBus(
    UiEvents.BatchPurchased,
    async () => {
      if (!isMobile && !isSidebarCollapsed) {
        const animation = getRandomPurchaseAnimation();
        logoRef.current?.play(animation);
      }
    },
    [isMobile],
  );

  // Trigger animation when a pirate has a reason to be sad (like losing a treasure)
  useBus(
    UiEvents.APirateIsSad,
    async () => {
      if (!isMobile && !isSidebarCollapsed) {
        const animation = sadAnimations[0];
        logoRef.current?.play(animation);
      }
    },
    [isMobile],
  );

  return (
    <Styled.SidebarWrapper collapsed={isSidebarCollapsed} willChangeWidth={willChangeWidth}>
      <Styled.MenuBar>
        <Styled.LogoLink collapsed={isSidebarCollapsed} to="/ship" bridgeHref="/ship">
          <RandomAnimationLogo
            aria-hidden
            ref={logoRef}
            animationInterval={LOGO_ANIMATION_INTERVAL}
            animationIntervalJitter={LOGO_ANIMATION_INTERVAL_JITTER}
            animationDoNotRepeatLastX={LOGO_ANIMATION_DO_NOT_REPEAT_LAST_X}
            animations={isMobile || isSidebarCollapsed ? [] : randomAnimations}
          />
        </Styled.LogoLink>
        <Styled.BurgerIconWrapper
          aria-label="Menu"
          aria-expanded={open}
          data-testid="burgerIcon"
          onClick={() => setOpen(!open)}
        >
          <BurgerIcon open={open} />
        </Styled.BurgerIconWrapper>
      </Styled.MenuBar>

      <Styled.Menu open={open}>
        <SidebarMenuItem
          data-testid="ship"
          to="/ship"
          bridgeHref="/ship"
          ref={shipMenuItemRef}
          label="Ship"
          onMouseEnter={() => setShowDrawer(true)}
          onMouseLeave={() => setShowDrawer(false)}
          onClick={() => setShowDrawer(false)}
          selectedRoutes={['/ship', '/pickup', '/scanform', '/import', '/batch']}
        />
        {!isMobile && (
          <SidebarDrawer
            sidebarWidth={isSidebarCollapsed ? SIDEBAR_WIDTH.collapsed : SIDEBAR_WIDTH.expanded}
            anchorTop={shipMenuItemRef.current?.offsetTop ?? 0}
            onMouseEnter={() => setShowDrawer(true)}
            onMouseLeave={() => setShowDrawer(false)}
            platformIconUrl={platformIconUrl}
            platformLink={platformLink}
            show={showDrawer}
          />
        )}
        <SidebarMenuItem
          data-testid="rates"
          label="Rates"
          to="/rates"
          bridgeHref="/rates"
          selectedRoutes={['/rates']}
        />
        <SidebarMenuItem
          data-testid="reports"
          label="Reports"
          to="/reports"
          bridgeHref="/reports"
          selectedRoutes={['/reports', '/payment/refund']}
        />
        <SidebarMenuItem
          data-testid="settings"
          label="Settings"
          to="/settings"
          bridgeHref="/settings"
          selectedRoutes={['/settings', '/pp/user']}
        />
        <SidebarMenuItem
          data-testid="support"
          label="Support"
          to={EXTERNAL_SUPPORT_URL}
          bridgeHref={EXTERNAL_SUPPORT_URL}
          target="_blank"
        />
        <SidebarMenuItem
          data-testid="logout"
          label="Logout"
          to="/logout"
          bridgeHref="#logout"
          onClick={(event) => {
            if (environment.isBridge()) {
              event.preventDefault();

              logout().then(() => {
                navigation.redirect('/');
              });
            }
          }}
        />
      </Styled.Menu>

      <Hidden xs>
        <Styled.CollapseButton
          aria-label={isSidebarCollapsed ? 'Expand sidebar visually' : 'Collapse sidebar visually'}
          onClick={onToggleSidebar}
          onMouseEnter={() => setWillChangeWidth(true)}
          onMouseLeave={() => setWillChangeWidth(false)}
        >
          <Icon icon={isSidebarCollapsed ? 'arrow-right' : 'arrow-left'} />
          <Styled.CollapseButtonText>
            {isSidebarCollapsed ? 'Expand' : 'Collapse'}
          </Styled.CollapseButtonText>
        </Styled.CollapseButton>
        <Styled.Footer>
          <Styled.FooterLinksContainer>
            © 2014-2024 - All Rights Reserved
            <br />
            <Styled.FooterLink
              data-testid="privacy"
              data-dd-action-name="Privacy Policy"
              onClick={() => {
                setModal('privacy');
              }}
            >
              Privacy Policy
            </Styled.FooterLink>
            {' - '}
            <Styled.FooterLink
              data-testid="terms"
              data-dd-action-name="Terms of Use"
              onClick={() => {
                setModal('terms');
              }}
            >
              Terms of Use
            </Styled.FooterLink>
            {' - '}
            <Styled.FooterLink
              data-testid="dpa"
              data-dd-action-name="DPA"
              onClick={() => {
                setModal('dpa');
              }}
            >
              DPA
            </Styled.FooterLink>
            {' - '}
            <a href="https://pirateship.com/cookies" data-dd-action-name="Cookies Notice">
              Cookies Notice
            </a>
            <a
              href="https://pirateship.com/dataprivacy"
              data-dd-action-name="Manage your Privacy & Data Settings"
            >
              <Styled.FooterIcon
                src={PrivacyOptionsIcon}
                alt="Privacy Options Icon"
                aria-hidden="true"
              />
              Manage your Privacy & Data Settings
            </a>
          </Styled.FooterLinksContainer>
          {isSupportAgent && (
            <>
              <Styled.FooterAdminBarToggle
                data-testid="adminBarToggle"
                title="Toggle Admin Bar"
                id="adminBarToggle"
                onClick={onToggleAdminBar}
              >
                ☠️
                <Styled.FooterAdminBarToggleText role="img" aria-labelledby="adminBarToggle">
                  Toggle Admin Bar
                </Styled.FooterAdminBarToggleText>
              </Styled.FooterAdminBarToggle>
              <SidebarApplicationInformation />
            </>
          )}
        </Styled.Footer>
      </Hidden>
    </Styled.SidebarWrapper>
  );
}
