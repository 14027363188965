import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const updateShipmentPresetMutation = gql(/* GraphQL */ `
  mutation UpdateShipmentPresetMutation($default: Boolean!, $shipmentPreset: ShipmentPresetInput!) {
    updateShipmentPreset(default: $default, shipmentPreset: $shipmentPreset) {
      shipmentPreset {
        ...ShipmentPresetFragment
      }
      default
    }
  }
`);

export const useUpdateShipmentPresetMutation = () =>
  useMutation(updateShipmentPresetMutation, {
    // Replace previous record as updates lead to a new record being created
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateShipmentPresetsAfterUpdateQuery {
              company {
                id
                shipmentPresets {
                  ...ShipmentPresetFragment
                }
                settings {
                  defaultShipmentPresetId
                }
              }
            }
          `),
        },

        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          const exitingEntries = cacheResult.company.shipmentPresets;
          const entryIndex = exitingEntries.findIndex(
            (entry) => entry.id === data.updateShipmentPreset.shipmentPreset.originalId,
          );

          if (entryIndex >= 0) {
            return {
              company: {
                ...cacheResult.company,
                shipmentPresets: [
                  ...exitingEntries.slice(0, entryIndex),
                  data.updateShipmentPreset.shipmentPreset,
                  ...exitingEntries.slice(entryIndex + 1),
                ],
                settings: {
                  ...cacheResult.company.settings,
                  defaultShipmentPresetId: data.updateShipmentPreset.default
                    ? data.updateShipmentPreset.shipmentPreset.id
                    : cacheResult.company.settings.defaultShipmentPresetId,
                },
              },
            };
          }

          return undefined;
        },
      );
    },
  });
