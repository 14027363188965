import NumberFormat from 'react-number-format';
import TextField, { TextFieldProps } from './TextField';

type PhoneTextFieldProps = {
  countryCode?: string;
  value?: string | number;
  name: string;
} & TextFieldProps;

const phoneFormat: Record<string, string> = {
  US: '(###) ###-####',
};

/**
 * Controlled component --
 * Formats phone numbers based on country code,
 * if none is provided, it will render a regular TextField without formatting
 */
function PhoneTextField({
  countryCode,
  value,
  defaultValue,
  name,
  ...others
}: PhoneTextFieldProps) {
  const format = countryCode ? phoneFormat[countryCode] : undefined;

  if (!format) {
    return (
      <TextField
        {...others}
        type="tel" // This will change the mobile keyboard layout to have only numbers
        value={value}
        name={name}
      />
    );
  }

  return (
    <NumberFormat
      {...others}
      type="tel" // This will change the mobile keyboard layout to have only numbers
      format={format}
      mask="_"
      customInput={TextField}
      name={name}
      value={value}
    />
  );
}

export default PhoneTextField;
