import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CUSTOM_ICON_DATA } from '../styles/icons';
import { PlatformKey, platformKeyToIconUrl } from '../assets/platformIcons';
import carrierKeyToIconUrl from '../utils/carrierKeyToIconUrl';
import { SPACING } from '../styles/spacing';
import { TYPOGRAPHY } from '../styles/typography';
import { COLOR, GREYSCALE } from '../styles/colors';

export type CustomIconName = (typeof CUSTOM_ICON_DATA)[number]['iconName'];

export interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  icon: CustomIconName | IconProp;
}

function Icon(props: IconProps): JSX.Element {
  const { icon } = props;
  return <FontAwesomeIcon {...props} icon={icon as IconProp} />;
}

const Styled = {
  PlatformIcon: styled.img<{ icon: PlatformKey; width: number; height: number }>`
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    margin-right: ${({ icon }) =>
      ['fakeshop', 'bigcartel', 'chargify'].includes(icon) ? SPACING.none : SPACING.sm};
  `,
  CarrierIcon: styled.img<{ carrierKey: string }>`
    height: ${({ carrierKey }) => {
      switch (carrierKey) {
        case 'UPS':
          return '22px';
        case 'USPS':
          return '17px';
        default:
          return '17px';
      }
    }};
  `,
  TooltipIcon: styled(Icon)`
    min-width: ${SPACING.lg};
    height: ${SPACING.lg};
    margin: 0 ${SPACING.xs};
    font-size: ${TYPOGRAPHY.fontSize.xs};
    color: ${GREYSCALE.grey50};
    vertical-align: middle;
    cursor: pointer;
    &:hover {
      color: ${COLOR.darkBlue};
    }
  `,
};

export type PlatformIconProps = {
  icon: PlatformKey;
  height: number;
  width: number;
};

export function PlatformIcon({ height, width, icon }: PlatformIconProps): JSX.Element {
  const iconUrl = platformKeyToIconUrl.get(icon);
  return <Styled.PlatformIcon src={iconUrl} icon={icon} height={height} width={width} />;
}

export type CarrierIconProps = {
  carrierKey: string;
  mailClassKey?: string | null;
};

export function CarrierIcon({ carrierKey, mailClassKey }: CarrierIconProps): JSX.Element {
  return (
    <Styled.CarrierIcon
      src={carrierKeyToIconUrl(carrierKey, mailClassKey)}
      carrierKey={carrierKey}
      data-testid={carrierKey}
    />
  );
}

export type TooltipIconProps = {
  id: string;
};

export function TooltipIcon({ id }: TooltipIconProps) {
  return <Styled.TooltipIcon id={id} icon="circle-question" />;
}

export default Icon;
