import { BATCH_REFUND_COPY } from '../constants/refundSuccessMessages';

function getBatchRefundSuccessMessage(
  canInstantRefundBatch: boolean,
  isUpsOnlyBatch: boolean,
  isUspsOnlyBatch: boolean,
) {
  if (canInstantRefundBatch) {
    return BATCH_REFUND_COPY.instant.success;
  }
  if (isUpsOnlyBatch) {
    return BATCH_REFUND_COPY.ups.success;
  }
  if (isUspsOnlyBatch) {
    return BATCH_REFUND_COPY.usps.success;
  }
  return BATCH_REFUND_COPY.default.success;
}

export default getBatchRefundSuccessMessage;
