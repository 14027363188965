import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePickerLink from '../../form/dateTimePickers/DatePickerLink';
import FormControl from '../../form/FormControl';
import Label from '../../form/Label';
import {
  StyledPurchaseAlignRightCol,
  StyledPurchaseCol,
  StyledPurchaseRow,
} from '../../layout/PurchaseGrid';
import LinkedIconTooltip from '../../LinkedIconTooltip';
import { NamespacedSubform } from '../types';

export type ShipDateSettingRowValues = {
  date: Date;
};

export type ShipDateSettingRowProps<NS extends string> = NamespacedSubform<NS> & {
  availableShipDates: Date[];
  onShipDateChange?: (shipDate: Date) => void;
};

export default function ShipDateSettingRow<NS extends string>({
  namespace,
  availableShipDates,
  onShipDateChange,
}: ShipDateSettingRowProps<NS>) {
  return (
    <StyledPurchaseRow align="center">
      <StyledPurchaseCol xs={5} md={8}>
        <Label>Ship Date</Label>
        <LinkedIconTooltip href="https://support.pirateship.com/en/articles/2235933-can-i-choose-my-ship-date">
          You can select a Ship Date up to 7 days in advance. Click the{' '}
          <FontAwesomeIcon icon="circle-question" /> below to learn more 👍
        </LinkedIconTooltip>
      </StyledPurchaseCol>
      <StyledPurchaseAlignRightCol xs={7} md={4}>
        <FormControl
          name={`${namespace}.date`}
          minDate={availableShipDates[0]}
          localOrUTC="local"
          maxDate={availableShipDates[availableShipDates.length - 1]}
          enabledDates={availableShipDates}
          as={DatePickerLink}
          onChange={onShipDateChange}
        />
      </StyledPurchaseAlignRightCol>
    </StyledPurchaseRow>
  );
}
