import styled from '@emotion/styled';
import { SPACING } from '../styles/spacing';
import { TYPOGRAPHY } from '../styles/typography';

/**
 * invisible fieldset to group input fields and to structure
 * labels for accessibility
 */
export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

// The Legend component lives inside the Fieldset component, because Fieldset require a legend to be valid HTML
/**
 * Semantic grouping of form fields with a title
 */
export const Legend = styled.legend`
  display: inline-block;
  padding: ${SPACING.sm} 0;
  font-size: ${TYPOGRAPHY.body.fontSize};
  font-weight: ${TYPOGRAPHY.fontWeight.medium};
`;
