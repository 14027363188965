import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useId, useMemo } from 'react';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import { addRumCustomAction } from '../../../utils/addRumCustomAttributes';
import formatPaymentSourceTitle from '../../../utils/formatPaymentSourceTitle';
import { FakeLink, LinkButton } from '../../Link';
import Tooltip from '../../form/Tooltip';
import { PurchaseDescriptionSubLabel, PurchaseNumberFormat } from '../../layout/PurchaseGrid';
import { PaymentSources } from '../FinishPurchaseSubform';

type FinishPurchaseBottomRowProps = {
  hasPaymentSource: boolean;
  toogleBtnComponent: ReactNode | ReactNode[];
  addPaymentMethodHandler: () => void;
  accountBalanceErrorMessage: string | undefined;
  selectedPaymentSource: PaymentSources[number];
  totalCharge: number;
  totalCost: number;
};

type FinishPurchasePromoProps = {
  addPaymentMethodHandler: () => void;
};

const Styled = {
  Span: styled.span`
    padding-right: ${SPACING.sm};
  `,
  PromoFlexLine: styled.div`
    display: flex;
    align-items: center;
    gap: ${SPACING.sm};
    @media (max-width: ${MEDIA_QUERY.smMax}) {
      gap: ${SPACING.md};
    }
  `,
  PromoLineEmoji: styled.span`
    font-size: ${TYPOGRAPHY.fontSize.xl};
    margin: calc(${TYPOGRAPHY.fontSize.sm} - ${TYPOGRAPHY.fontSize.xl}) 0;
  `,
  PromoLink: styled.a`
    color: ${GREYSCALE.grey50};
    &:hover,
    &:active {
      color: ${COLOR.darkBlue};
    }
    svg {
      margin-inline: 0.4em;
      margin-bottom: -2px;
    }
  `,
};

export function FinishPurchasePromo({ addPaymentMethodHandler }: FinishPurchasePromoProps) {
  const tooltipId = useId();

  useMemo(() => {
    addRumCustomAction('plaid_3%_promo_display', { event: 'plaid_3%_promo_display' });
  }, []);

  return (
    <Styled.PromoFlexLine>
      <Styled.PromoLineEmoji>😀</Styled.PromoLineEmoji>
      <span data-testid="promo-line">
        Save 3% on your first Pay by Bank payment
        <Styled.PromoLink
          id={tooltipId}
          href="https://plaid.com/promo-details/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon="question-circle" />
          <Tooltip anchorSelector={`#${CSS.escape(tooltipId)}`}>
            Click
            <FontAwesomeIcon icon="question-circle" />
            to find out more
          </Tooltip>
        </Styled.PromoLink>
        <LinkButton onClick={addPaymentMethodHandler} data-dd-action-name="plaid_3%_promo_click">
          Change payment method
        </LinkButton>
      </span>
    </Styled.PromoFlexLine>
  );
}

export default function FinishPurchaseBottomRow({
  hasPaymentSource,
  addPaymentMethodHandler,
  toogleBtnComponent,
  accountBalanceErrorMessage,
  selectedPaymentSource,
  totalCharge,
  totalCost,
}: FinishPurchaseBottomRowProps) {
  // 1. user has no payment method in settings
  if (!hasPaymentSource) {
    return (
      <PurchaseDescriptionSubLabel>
        <Styled.Span data-testid="purchase-row-message">
          You need to add a <FakeLink onClick={addPaymentMethodHandler}>Payment Method</FakeLink>
        </Styled.Span>{' '}
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 2. account balance error message is present
  if (accountBalanceErrorMessage !== undefined) {
    return (
      <PurchaseDescriptionSubLabel isError>
        <Styled.Span data-testid="purchase-row-message">{accountBalanceErrorMessage}</Styled.Span>
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 3. Rating failed & no account balance error message
  if (totalCost === 0) {
    return (
      <PurchaseDescriptionSubLabel data-testid="purchase-row-message">
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 4. user has payment method but totalcharge is 0
  if (totalCharge === 0) {
    return (
      <PurchaseDescriptionSubLabel>
        <span data-testid="purchase-row-message">
          We&apos;re going to deduct <PurchaseNumberFormat value={totalCost} /> from your account
          balance
        </span>{' '}
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 5. standard case
  return (
    <PurchaseDescriptionSubLabel>
      <Styled.Span data-testid="purchase-row-message">
        You&apos;re going to be charged <PurchaseNumberFormat value={totalCharge} /> via{' '}
        {formatPaymentSourceTitle(selectedPaymentSource)}
      </Styled.Span>
      {toogleBtnComponent}
    </PurchaseDescriptionSubLabel>
  );
}
