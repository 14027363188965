// import { SharePrintLabelsPage } from '@ps/printLabels';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { ComponentType, lazy, MouseEvent, StrictMode, Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { IntercomProvider } from 'react-intercom-hook';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { dispatch } from 'use-bus';
import { setFlashMessage } from '../apollo/cache/flashMessages';
import initApolloClient from '../apollo/client';
import Alert from '../components/Alert';
import FlashMessages from '../components/FlashMessages';
import GlobalIntercomArticle from '../components/GlobalIntercomArticle';
import RootErrorBoundary from '../components/RootErrorBoundary';
import DropdownButton, { DropdownButtonProps } from '../components/form/DropdownButton';
import Tooltip from '../components/form/Tooltip';
import Sidebar, { SidebarProps } from '../components/layout/sidebar/Sidebar';
import ContentLoading from '../components/loading/ContentLoading';
import { ModalRef } from '../components/modals/Modal';
import UpsAccountModals from '../components/modals/UpsAccountModals';
import UspsAddressCorrectionModal from '../components/modals/UspsAddressCorrectionModal';
import { STORAGE_KEYS, UiEvents } from '../constants';
import useIntercomArticle from '../hooks/useIntercomArticle';
import initLocale from '../locale';
import '../polyfills';
import loggingService from '../services/logging';
import { getItem as getStorageItem, setItem as setStorageItem } from '../services/storage';
import initGrid from '../styles/grid';
import initIcons from '../styles/icons';
import initValidation from '../validation';
import BridgeApp from './BridgeApp';
import BridgeModal from './BridgeModal';
import BridgeStyles from './BridgeStyles';
import AppVersionReloader from '../components/AppVersionReloader';
import GlobalLegalTextsModal from '../components/GlobalLegalTextsModal';
import GlobalUserEmailVerificationModals from '../components/GlobalUserEmailVerificationModals';
import DropdownMultiSelect, {
  DropdownMultiSelectOption,
  DropdownMultiSelectProps,
} from '../components/form/DropdownMultiSelect';
import ImportRoutes from '../components/pages/ImportRoutes';
import ReportsRoutes from '../components/pages/ReportsRoutes';
import ResetPasswordPage from '../components/pages/loggedout/ResetPasswordPage';
import { setFatalError } from '../apollo/cache/fatalError';
import CarrierAdjustmentsPage from '../components/pages/reports/CarrierAdjustmentsPage';
import useCurrentUser from '../hooks/useCurrentUser';
import ShipRoutes from '../components/pages/ShipRoutes';
import ShopifyRegistrationPage, {
  ShopifyUserDataProps,
} from '../components/pages/loggedout/ShopifyRegistrationPage';
import TermsOfUsePage from '../components/pages/legal/TermsOfUsePage';
import PrivacyPage from '../components/pages/legal/PrivacyPage';
import DpaPage from '../components/pages/legal/DpaPage';
import ScrollToTop from '../components/layout/ScrollToTop';
import TwoFactorAuthenticationChallengePage from '../components/pages/loggedout/TwoFactorAuthenticationChallengePage';
import LandlubberContent from '../components/layout/LandlubberContent';
import { userQuery } from '../operations/queries/user';
import InvitePasswordPage from '../components/pages/loggedout/InvitePasswordPage';

const SharePrintLabelsPage = lazy(() => import('@ps/printLabels/pages/SharePrintLabelsPage'));
const UploadPage = lazy(() => import('../components/pages/ship/UploadMappingPage'));
const WelcomeAboard = lazy(() => import('../components/WelcomeAboard'));
const BuyPage = lazy(() => import('../components/pages/ship/BuyPage'));
const BlockedCountriesPage = lazy(() => import('../components/pages/admin/BlockedCountriesPage'));
const ClientsPage = lazy(() => import('../components/pages/support/ClientsPage'));
const PickupOverviewPage = lazy(() => import('../components/pages/pickup/PickupOverviewPage'));
const CreateUspsPickupPage = lazy(() => import('../components/pages/pickup/CreateUspsPickupPage'));
const CreateUpsPickupPage = lazy(() => import('../components/pages/pickup/CreateUpsPickupPage'));
const RatesPage = lazy(() => import('../components/pages/RatesPage'));
const PublicRatesPage = lazy(() => import('../components/pages/PublicRatesPage'));
const ShipOverviewPage = lazy(() => import('../components/pages/ship/ShipOverviewPage'));
const ShipmentsPage = lazy(() => import('@ps/complete/pages/ShipmentsPage'));
const AddCreditPage = lazy(() => import('../components/pages/reports/AddCreditPage'));
const ReceiptPage = lazy(() => import('../components/pages/reports/ReceiptPage'));
const PaymentReceiptsPage = lazy(() => import('../components/pages/reports/PaymentReceiptsPage'));
const RefundsPage = lazy(() => import('../components/pages/reports/RefundsPage'));
const ReturnLabelsPage = lazy(() => import('../components/pages/reports/ReturnsPage'));
const UploadFormPage = lazy(() => import('../components/pages/ship/UploadFormPage'));
const ForgotPasswordPage = lazy(() => import('../components/pages/loggedout/ForgotPasswordPage'));

const SettingsRoutes = lazy(() => import('../components/pages/SettingsRoutes'));
const ContextualDropdownMenu = lazy(() => import('../components/ContextualDropdownMenu'));

const LoginPage = lazy(() => import('../components/pages/loggedout/LoginPage'));
const SignupPage = lazy(() => import('../components/pages/loggedout/SignupPage'));
const SignupCompletePage = lazy(() => import('../components/pages/SignupCompletePage'));

const UserEmailVerificationMessage = lazy(
  () => import('../components/notifications/UserEmailVerificationMessage'),
);

initLocale('en-US');
initValidation();
initIcons();
initGrid();

let graphqlEndpoint: string;
let apolloClient: ApolloClient<NormalizedCacheObject>;
let globalsRendered = false;
let modalOpen = false;

function getApolloClient() {
  if (!graphqlEndpoint) {
    throw new Error('GraphQL endpoint is not configured yet');
  }

  if (!apolloClient) {
    apolloClient = initApolloClient({
      endpoint: graphqlEndpoint,
      onNetworkError: (networkError) => {
        setFlashMessage(networkError.message, 'danger');
      },
      onGraphQLError: (graphQLErrors, operation) => {
        // Display GraphQL error to the user in case the error was not already handled in the component itself.
        // We do not need to log the error here as it is already being logged in the backend.
        const { errorHandled } = operation.getContext();
        const graphQLError = graphQLErrors[0];

        if (errorHandled) {
          return;
        }

        // Show rate limiting as fatal error
        if (graphQLError.extensions?.category === 'rate-limiting') {
          setFatalError(graphQLError.message, 60000);
          return;
        }

        setFlashMessage(graphQLError.message, 'danger');
      },
    });

    // Watch for feature flags to be loaded and send them to Datadog
    apolloClient.watchQuery({ query: userQuery, fetchPolicy: 'cache-only' }).subscribe({
      next: ({ data }) => {
        data.user?.company.features.forEach((feature) => {
          window.DD_RUM?.addFeatureFlagEvaluation(feature.key, feature.value);
        });
      },
    });
  }

  return apolloClient;
}

function renderGlobals() {
  if (globalsRendered) {
    return null;
  }

  globalsRendered = true;

  return (
    <Suspense fallback={null}>
      <BridgeStyles />
      <GlobalIntercomArticle />
      <GlobalLegalTextsModal />
      <GlobalUserEmailVerificationModals />
    </Suspense>
  );
}

function asStandaloneComponent(Component: ComponentType) {
  return (
    <BrowserRouter future={{ v7_startTransition: false }}>
      <AppVersionReloader />
      <IntercomProvider autoBoot appId="no_appId_bc_overwritten_by_legacy_ship">
        <ApolloProvider client={getApolloClient()}>
          {renderGlobals()}
          <Suspense fallback={<ContentLoading />}>
            <Component />
          </Suspense>
        </ApolloProvider>
      </IntercomProvider>
    </BrowserRouter>
  );
}

function asContentPage(Component: ComponentType) {
  return asStandaloneComponent(() => (
    <>
      <ScrollToTop />
      <FlashMessages />
      <BridgeApp>
        <Component />
      </BridgeApp>
    </>
  ));
}

function asLandlubberPage(Component: ComponentType) {
  return asStandaloneComponent(() => (
    <>
      <ScrollToTop />
      <LandlubberContent full>
        <FlashMessages />
      </LandlubberContent>
      <BridgeApp>
        <Component />
      </BridgeApp>
    </>
  ));
}

function renderTo(rootEl: Element, element: JSX.Element): () => void {
  const logger = loggingService.getLogger();
  const root = createRoot(rootEl);

  root.render(
    <StrictMode>
      <RootErrorBoundary logger={logger}>
        {(error) => {
          // this is for all errors that are not caught by the Apollo Client (e.g. Javascript errors)
          if (error) {
            return (
              <Alert variant="danger">
                <strong>{`An unexpected error occurred. Log-ID: ${error.logId}`}</strong>
              </Alert>
            );
          }

          return element;
        }}
      </RootErrorBoundary>
    </StrictMode>,
  );

  return () => root.unmount();
}

const api = {
  setGraphqlEndpoint(endpoint: string) {
    graphqlEndpoint = endpoint;
  },
  renderRatesCalculator(root: HTMLElement) {
    return renderTo(root, asContentPage(RatesPage));
  },
  renderPublicRatesCalculator(root: HTMLElement) {
    return renderTo(root, asContentPage(PublicRatesPage));
  },
  renderSupportClientGrid(root: HTMLElement) {
    return renderTo(root, asContentPage(ClientsPage));
  },
  renderCovid(root: HTMLElement) {
    return renderTo(root, asContentPage(BlockedCountriesPage));
  },
  renderUpload(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => {
        const [searchParams] = useSearchParams();
        const id = searchParams.get('file');
        if (id === null) {
          return (
            <Suspense fallback={null}>
              <BridgeStyles />
              <GlobalIntercomArticle />
            </Suspense>
          );
        }
        return <UploadPage bridgeFileId={id} />;
      }),
    );
  },
  renderAddCredit(root: HTMLElement) {
    return renderTo(root, asContentPage(AddCreditPage));
  },
  renderUspsAccount(
    root: HTMLElement,
    open: boolean,
    onCancel: () => void,
    onAccountCreated: () => void,
  ) {
    return renderTo(
      root,
      asStandaloneComponent(() => (
        <UspsAddressCorrectionModal
          open={open}
          onCancel={onCancel}
          onAccountCreated={onAccountCreated}
        />
      )),
    );
  },
  renderUpsAccountModal(
    root: HTMLElement,
    showModal: boolean,
    onCancel: () => void,
    onMerchantCreated: () => void,
    headline: string,
    buttonText: string,
  ) {
    return renderTo(
      root,
      asStandaloneComponent(() => {
        const [open, setModalOpen] = useState<boolean>(showModal);
        return (
          <UpsAccountModals
            buttonText={buttonText}
            headline={headline}
            open={open}
            onAccountCreatedOrUpdated={() => {
              if (onMerchantCreated) {
                onMerchantCreated();
              }
              setModalOpen(false);
            }}
            onCancel={() => {
              onCancel();
              setModalOpen(false);
            }}
          />
        );
      }),
    );
  },
  updateRateGroupsState: null,
  renderIntercomModal(root: HTMLElement) {
    return renderTo(
      root,
      asStandaloneComponent(() => {
        const openIntercomArticle = useIntercomArticle();

        useEffect(() => {
          (window as any).openIntercomArticle = openIntercomArticle;

          return () => {
            delete (window as any).openIntercomArticle;
          };
        }, [openIntercomArticle]);

        return null;
      }),
    );
  },
  // if the user select a mailClass from the inAppCalc the mailClass should be pre selected
  renderBuyPage(root: HTMLElement, forwardedMailClassKey?: string) {
    return renderTo(
      root,
      asContentPage(() => {
        const params = new URLSearchParams(useLocation().search);
        const id = params.get('id') || undefined;
        return <BuyPage entityId={id} forwardedMailClassKey={forwardedMailClassKey} />;
      }),
    );
  },
  renderShipOverviewPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => <ShipOverviewPage />),
    );
  },
  renderUploadForm(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => {
        const params = new URLSearchParams(useLocation().search);
        const id = params.get('id') || undefined;

        return <UploadFormPage entityId={id} />;
      }),
    );
  },
  renderShipmentWorkflow(root: HTMLElement, forwardedMailClassKey?: string) {
    return renderTo(
      root,
      asContentPage(() => <ShipRoutes forwardedMailClassKey={forwardedMailClassKey} />),
    );
  },
  renderPickupPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="/pickup/*">
            <Route index element={<PickupOverviewPage />} />
            <Route path="usps" element={<CreateUspsPickupPage />} />
            <Route path="ups" element={<CreateUpsPickupPage />} />
          </Route>
        </Routes>
      )),
    );
  },
  renderSidebar(
    root: HTMLElement,
    currentUserRoles: SidebarProps['currentUserRoles'],
    onCollapse: (isSidebarCollapsed: boolean) => void,
    onToggleAdminBar: (isAdminBarVisible: boolean) => void,
    initialAdminBarVisible?: boolean,
  ) {
    return renderTo(
      root,
      asStandaloneComponent(() => {
        const [isSidebarCollapsed, setSidebarCollapsed] = useState(() =>
          getStorageItem(STORAGE_KEYS.sidebarCollapsedStorageKey),
        );
        const [isAdminBarVisible, setAdminBarVisible] = useState(
          () => initialAdminBarVisible ?? getStorageItem(STORAGE_KEYS.adminBarVisibleStorageKey),
        );
        const [currentUser] = useCurrentUser();

        // Handle sidebar toggle
        useEffect(() => {
          setStorageItem(STORAGE_KEYS.sidebarCollapsedStorageKey, isSidebarCollapsed);
          onCollapse(isSidebarCollapsed);
        }, [isSidebarCollapsed]);

        // Handle admin bar toggle
        useEffect(() => {
          setStorageItem(STORAGE_KEYS.adminBarVisibleStorageKey, isAdminBarVisible);
        }, [isAdminBarVisible]);

        return (
          <Sidebar
            currentUserRoles={currentUserRoles}
            activePlatformKeys={
              currentUser?.activePlatforms.map((platform) => platform.platformKey) || []
            }
            onToggleSidebar={() => setSidebarCollapsed(!isSidebarCollapsed)}
            isSidebarCollapsed={isSidebarCollapsed}
            onToggleAdminBar={() => {
              setAdminBarVisible(!isAdminBarVisible);
              // call onToggleAdminBar from here because of legacy code checking for the cookie
              onToggleAdminBar(isAdminBarVisible);
            }}
          />
        );
      }),
    );
  },
  triggerBatchPurchasedLogoAnimation() {
    dispatch(UiEvents.BatchPurchased);
  },
  renderWelcomeAboard(root: HTMLElement) {
    return renderTo(
      root,
      asStandaloneComponent(() => <WelcomeAboard />),
    );
  },
  renderSharePrintLabelsPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => {
        const [searchParams] = useSearchParams();
        return <SharePrintLabelsPage shareToken={searchParams.get('token') ?? ''} />;
      }),
    );
  },
  renderReports(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="reports/*" element={<ReportsRoutes />} />
        </Routes>
      )),
    );
  },

  renderReceiptPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="/reports/*">
            <Route path="paymentreceipt" element={<PaymentReceiptsPage />} />
            <Route path="receipt/:paymentId" element={<ReceiptPage />} />
          </Route>
        </Routes>
      )),
    );
  },

  renderRefundsPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="/reports/*">
            <Route path="refund" element={<RefundsPage />} />
          </Route>
        </Routes>
      )),
    );
  },

  renderReturnLabelsPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="/reports/*">
            <Route path="return" element={<ReturnLabelsPage />} />
          </Route>
        </Routes>
      )),
    );
  },

  renderShipmentsPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => {
        const params = useParams<'filterTemplateName'>();
        const [searchParams] = useSearchParams();
        return (
          <Routes>
            <Route path="/reports/*">
              <Route
                path="shipment/:filterTemplateName"
                element={<ShipmentsPage bridgeFilterTemplateName={params.filterTemplateName} />}
              />
              <Route
                path="shipment"
                element={
                  <ShipmentsPage
                    bridgeFilterTemplateName={searchParams.get('tracking') ?? undefined} // filter template name has the search param name "tracking" in legacy
                    searchFromLegacyShipOverview={searchParams.get('s') ?? undefined} // search from ship page uses just "s"
                  />
                }
              />
            </Route>
          </Routes>
        );
      }),
    );
  },
  showElementInModal(
    element: HTMLElement,
    {
      width,
      fullHeight,
      dark,
      onOpen,
      onClose,
    }: {
      width?: number;
      fullHeight?: boolean;
      dark?: boolean;
      onOpen?: () => void;
      onClose?: () => void;
    },
  ): () => void {
    const dummyRoot = document.createElement('div');
    document.body.appendChild(dummyRoot);

    // Modal mutex
    if (modalOpen) {
      return () => {};
    }

    let modalInstance: ModalRef | null = null;

    const unmount = renderTo(
      dummyRoot,
      <Suspense fallback={null}>
        <BridgeModal
          ref={(instance) => {
            modalInstance = instance;
          }}
          element={element}
          width={width}
          fullHeight={fullHeight}
          dark={dark}
          onOpen={() => {
            if (onOpen) {
              onOpen();
            }
          }}
          onClose={() => {
            unmount();
            document.body.removeChild(dummyRoot);
            modalOpen = false;

            if (onClose) {
              onClose();
            }
          }}
        />
      </Suspense>,
    );
    modalOpen = true;

    return () => {
      modalInstance?.closeDialog();
    };
  },
  renderDropdownButton(
    root: HTMLElement,
    title: string,
    options: Array<{ title: string; disabled?: boolean; onClick: (event: MouseEvent) => void }>,
    props: Omit<DropdownButtonProps<any>, 'title' | 'options' | 'onSelect'> = {},
  ) {
    const dropdownOptions = options.map((option) => ({
      value: option.onClick,
      title: option.title,
      disabled: option.disabled,
    }));

    return renderTo(
      root,
      asStandaloneComponent(() => (
        <DropdownButton
          {...props}
          title={title}
          options={dropdownOptions}
          onClick={(event) => event.stopPropagation()}
          onSelect={(clickHandler, event) => clickHandler(event)}
        />
      )),
    );
  },
  renderDropdownMultiSelect(
    root: HTMLElement,
    options: DropdownMultiSelectOption[],
    onChange: DropdownMultiSelectProps['onChange'],
    initialValues: DropdownMultiSelectProps['value'],
    props: Omit<DropdownMultiSelectProps, 'options' | 'onChange' | 'onClick' | 'value'> = {},
  ) {
    return renderTo(
      root,
      asStandaloneComponent(() => {
        const [value, setValue] = useState<DropdownMultiSelectProps['value']>(initialValues);
        return (
          <DropdownMultiSelect
            {...props}
            options={options}
            onChange={(updatedValue, event) => {
              setValue(updatedValue);
              if (onChange && updatedValue) {
                onChange(updatedValue, event);
              }
            }}
            value={value}
          />
        );
      }),
    );
  },
  renderTooltip(
    root: HTMLElement,
    anchorSelector: string,
    content: string,
    isHtml: boolean,
    placement: 'bottom' | 'left' | 'right' | 'top' = 'top',
  ) {
    return renderTo(
      root,
      <Tooltip anchorSelector={anchorSelector} placement={placement}>
        {isHtml ? (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          content
        )}
      </Tooltip>,
    );
  },
  renderSettings(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="/settings/*" element={<SettingsRoutes />} />
        </Routes>
      )),
    );
  },
  renderImportGrid(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="/import/*" element={<ImportRoutes />} />
        </Routes>
      )),
    );
  },
  renderReportsAdjustmentPage(root: HTMLElement) {
    return renderTo(
      root,
      asContentPage(() => (
        <Routes>
          <Route path="/reports/*">
            <Route path="carrieradjustment" element={<CarrierAdjustmentsPage />} />
          </Route>
        </Routes>
      )),
    );
  },
  renderContextualDropdownMenu(root: HTMLElement) {
    return renderTo(
      root,
      asStandaloneComponent(() => <ContextualDropdownMenu />),
    );
  },
  renderLogin(root: HTMLElement, recaptchaKey: string) {
    return renderTo(
      root,
      asStandaloneComponent(() => (
        <BridgeApp>
          <LoginPage onLogin={() => {}} recaptchaKey={recaptchaKey} />
        </BridgeApp>
      )),
    );
  },
  renderForgotPasswordPage(root: HTMLElement) {
    return renderTo(
      root,
      asLandlubberPage(() => <ForgotPasswordPage />),
    );
  },
  renderResetPasswordPage(root: HTMLElement) {
    return renderTo(
      root,
      asLandlubberPage(() => <ResetPasswordPage />),
    );
  },
  renderInvitePasswordPage(root: HTMLElement) {
    return renderTo(
      root,
      asLandlubberPage(() => <InvitePasswordPage />),
    );
  },
  renderShopifyRegistrationPage(root: HTMLElement, props: ShopifyUserDataProps) {
    return renderTo(
      root,
      asStandaloneComponent(() => (
        <BridgeApp>
          <ShopifyRegistrationPage {...props} />
        </BridgeApp>
      )),
    );
  },
  renderTermsOfUsePage(root: HTMLElement) {
    return renderTo(
      root,
      asStandaloneComponent(() => (
        <BridgeApp>
          <TermsOfUsePage />
        </BridgeApp>
      )),
    );
  },
  renderPrivacyPage(root: HTMLElement) {
    return renderTo(
      root,
      asStandaloneComponent(() => (
        <BridgeApp>
          <PrivacyPage />
        </BridgeApp>
      )),
    );
  },
  renderDpaPage(root: HTMLElement) {
    return renderTo(
      root,
      asStandaloneComponent(() => (
        <BridgeApp>
          <DpaPage />
        </BridgeApp>
      )),
    );
  },
  renderSignupPage(root: HTMLElement, email?: string) {
    return renderTo(
      root,
      asLandlubberPage(() => (
        <Routes>
          <Route path="/signup" element={<SignupPage initialValues={{ email: email ?? '' }} />} />
          <Route path="/signup/completed" element={<SignupCompletePage />} />
        </Routes>
      )),
    );
  },
  renderUserEmailVerificationMessage(root: HTMLElement) {
    return renderTo(
      root,
      asStandaloneComponent(() => <UserEmailVerificationMessage />),
    );
  },
  renderTwoFactorAuthenticationChallengePage(
    root: HTMLElement,
    provider: 'APP' | 'EMAIL',
    remember?: boolean,
  ) {
    return renderTo(
      root,
      asLandlubberPage(() => (
        <TwoFactorAuthenticationChallengePage rememberLogin={remember} provider={provider} />
      )),
    );
  },
};

// Bind to window
(window as any).nextGen = api;

// Dispatch ready event
const event = new Event('nextGenReady');
(event as any).nextGen = api;
document.dispatchEvent(event);
