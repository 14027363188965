import { gql } from '../../gql';

const refundShipmentMutation = gql(/* GraphQL */ `
  mutation RefundShipmentMutation($shipmentId: ID!) {
    refundShipment(shipmentId: $shipmentId) {
      id
      canPrint
      canRefund
      canInstantRefundBatch
      shipmentStatusSummary {
        refundableCount
      }
      shipments {
        id
        status
        printed
        canPrint
        canInstantRefundShipment
        additionalRefundNotice
        stageDots {
          title
          status
          message
        }
      }
    }
  }
`);

export default refundShipmentMutation;
