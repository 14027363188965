import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import DropdownSelect, {
  DropdownSelectOption,
  DropdownSelectProps,
  StyledDropdownSelectWrapper,
} from './DropdownSelect';

type ExpandableDropdownSelectWrapperProps = {
  isExpanded: boolean;
};

const Styled = {
  ExpandableDropdownSelectWrapper: styled.div<ExpandableDropdownSelectWrapperProps>`
    ${StyledDropdownSelectWrapper} {
      border-radius: ${({ isExpanded }) =>
        isExpanded
          ? `${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.none} ${BORDER_RADIUS.none}`
          : BORDER_RADIUS.sm};
    }
  `,
  ExpandedContent: styled.div`
    padding: ${SPACING.xl} ${SPACING.xl} ${SPACING.none};
    border-style: solid;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm};
    border-radius: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm};
    border-color: ${GREYSCALE.grey30};
    background-color: ${GREYSCALE.grey10};
  `,
};

type ExpandableDropdownSelectProps<
  V extends string | number,
  O extends DropdownSelectOption<V>,
> = DropdownSelectProps<V, O> & {
  expandedContent?: ReactNode;
};

export default function ExpandableDropdownSelect<
  V extends string | number,
  O extends DropdownSelectOption<V>,
>({ expandedContent, ...others }: ExpandableDropdownSelectProps<V, O>) {
  const isExpanded = expandedContent != null;

  return (
    <Styled.ExpandableDropdownSelectWrapper isExpanded={isExpanded}>
      <DropdownSelect {...others} />

      {isExpanded && <Styled.ExpandedContent>{expandedContent}</Styled.ExpandedContent>}
    </Styled.ExpandableDropdownSelectWrapper>
  );
}
