import { useEffect } from 'react';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import usePlaid, { PlaidHookProps } from '../../hooks/usePlaid';
import { PlaidAccessTokenProps, PlaidSuccessCallbackProps } from '../../hooks/usePlaidAccessToken';
import { PlaidLinkTokenProps } from '../../hooks/usePlaidLinkToken';
import useAriaAnnouncer from '../../hooks/useAriaAnnouncer';

export default function PlaidRelinkModal({
  open,
  origin,
  paymentSourceId,
  onClose,
  onSuccess,
  onRelinkError,
}: {
  open: boolean;
  origin: string;
  paymentSourceId: string;
  onClose: () => void;
  onSuccess?: (payload: PlaidSuccessCallbackProps) => void;
  onRelinkError?: (msg: string) => void;
}) {
  const announce = useAriaAnnouncer();
  const { getPlaidLinkToken } = usePlaid({
    onSuccessCallback(payload) {
      if (payload.successMessage) {
        setFlashMessage(payload.successMessage, 'success');
        announce(payload.successMessage, 'polite');
      }

      onSuccess?.(payload);
      onClose();
    },
    onErrorCallback(msg) {
      setFlashMessage(msg, 'danger');
      announce(msg, 'assertive');

      onRelinkError?.(msg);
      onClose();
    },
    onEventCallback(event) {
      if (event === 'EXIT') {
        onClose();
      }
    },
    forceOpen: true,
  } as PlaidAccessTokenProps & PlaidLinkTokenProps & PlaidHookProps);

  useEffect(() => {
    if (open) {
      getPlaidLinkToken(origin, paymentSourceId);
    }
    // Exclude getPlaidLinkToken from deps to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, paymentSourceId]);

  return null;
}
