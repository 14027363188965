import { Routes, Route } from 'react-router-dom';
import { ShipmentsPage } from '@ps/complete';
import OverviewReportsPage from './reports/ReportsOverviewPage';
import RefundPage from './reports/RefundsPage';
import ReturnPage from './reports/ReturnsPage';
import CarrierAdjustmentPage from './reports/CarrierAdjustmentsPage';
import PaymentReceiptPage from './reports/PaymentReceiptsPage';
import AddCreditPage from './reports/AddCreditPage';
import RefundCreditPage from './reports/RefundCreditPage';
import ReceiptPage from './reports/ReceiptPage';

export default function ReportsRoutes() {
  return (
    <Routes>
      <Route index element={<OverviewReportsPage />} />
      <Route path="return" element={<ReturnPage />} />
      <Route path="refund" element={<RefundPage />} />
      <Route path="carrieradjustment" element={<CarrierAdjustmentPage />} />
      <Route path="paymentreceipt" element={<PaymentReceiptPage />} />
      <Route path="shipment/:filterTemplateName" element={<ShipmentsPage />} />
      <Route path="shipment" element={<ShipmentsPage />} />
      <Route path="addcredit" element={<AddCreditPage />} />
      <Route path="refundcredit" element={<RefundCreditPage />} />
      <Route path="receipt/:paymentId" element={<ReceiptPage />} />
    </Routes>
  );
}
