import Label from '../../../../form/Label';
import AddressSubform from '../../../../subforms/AddressSubform';
import { SignupFormAddresses } from '../SignupFormValues';

type SignupOriginAddressProps = {
  onPaste: () => void;
  columnLayout?: boolean;
};

export default function SignupOriginAddress({
  onPaste,
  columnLayout = false,
}: SignupOriginAddressProps) {
  return (
    <>
      <Label
        tooltip="USPS requires your physical address, which is used for calculating shipping costs and scheduling pickups."
        data-testid="signup-label-origin"
      >
        Provide your physical address
      </Label>
      <AddressSubform<keyof SignupFormAddresses>
        namespace="originAddress"
        onPaste={onPaste}
        phone="US"
        nameRequired
        columnLayout={columnLayout}
      />
    </>
  );
}
