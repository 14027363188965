import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const completeBatchFromUploadMutation = gql(/* GraphQL */ `
  mutation CompleteBatchFromUploadMutation(
    $batchId: ID!
    $batchTitle: String!
    $warehouseId: ID!
    $shipmentPresetId: ID!
  ) {
    completeBatchFromUpload(
      batchId: $batchId
      batchTitle: $batchTitle
      warehouseId: $warehouseId
      shipmentPresetId: $shipmentPresetId
    ) {
      id
      title
      runningProcess {
        status
        progressTitle
      }
    }
  }
`);

export const useCompleteBatchFromUploadMutation = () =>
  useMutation(completeBatchFromUploadMutation);
