import { gql } from '../../gql';

const batchProcessStatusQuery = gql(/* GraphQL */ `
  query BatchProcessStatusQuery($id: ID!) {
    batch(id: $id) {
      id
      status
      ...RunningProcessFragment
      canInstantRefundBatch
      shipments {
        id
        carrierKey
      }
    }
  }
`);

export default batchProcessStatusQuery;
