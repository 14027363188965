import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useIsMobile from '../../hooks/useIsMobile';
import { Shipment, StageDot } from '../../gql/graphql';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { COLOR, GREYSCALE, PirateShipColor } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import convertBbcode from '../../utils/convertBbcode';

type ShipmentStageDotsProps = {
  stageDots: Shipment['stageDots'];
};

const dotSize = 32;

const Styled = {
  StagesWrapper: styled.ul`
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: ${SPACING.none};
    padding: ${SPACING.none};
  `,
  StageWrapper: styled.li`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    color: ${GREYSCALE.grey60};
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      flex-direction: row;
    }
  `,
  BarsWrapper: styled.div`
    position: relative;
  `,
  Bars: styled.div<{ splitIndex: number }>`
    position: absolute;
    display: flex;
    padding-left: 10%; // each item takes 20%, so the bar should start halfway across a 20% width item -> 10%
    padding-right: 10%;
    left: ${SPACING.none};
    right: ${SPACING.none};
    top: ${dotSize / 2 - dotSize / 8}px;
  `,
  Bar: styled.div<{ color: string; startIndex: number; endIndex: number }>`
    position: relative;
    flex: ${({ startIndex, endIndex }) => endIndex - startIndex};
    height: ${dotSize / 4}px; // aesthetics
    background-color: ${({ color }) => color};
  `,
  Dot: styled.div<{ color: string }>`
    width: ${dotSize}px;
    height: ${dotSize}px;
    border-radius: ${dotSize / 2}px;
    background-color: ${({ color }) => color};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  StageTexts: styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: ${SPACING.sm};
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      text-align: left;
      padding-left: ${SPACING.md};
      padding-top: ${SPACING.none};
    }
  `,
  StageTitle: styled.span<{ color: string; bold: boolean }>`
    color: ${({ color }) => color};
    font-weight: ${({ bold }) =>
      bold ? TYPOGRAPHY.fontWeight.bold : TYPOGRAPHY.fontWeight.regular};
  `,
  StageMessage: styled.small`
    color: ${GREYSCALE.grey50};
  `,
};

export default function ShipmentStageDots({ stageDots }: ShipmentStageDotsProps) {
  const hasError = stageDots.some((dot) => dot.status === 'error');
  // if we have an error, the progress index should be the furthest right dot, i.e. the 5th element in the array
  const progressIndex = hasError ? 4 : stageDots.findIndex((dot) => dot.status === 'active');

  const { isMobile } = useIsMobile();

  const renderSection = ({ title, status, message }: StageDot, index: number) => {
    if (isMobile && index !== progressIndex) return null;

    const dotColor: PirateShipColor = (() => {
      switch (status) {
        case 'success':
        case 'active':
          return COLOR.green;
        case 'error':
          return COLOR.red;
        default:
          return GREYSCALE.grey80;
      }
    })();

    const titleColor: PirateShipColor = (() => {
      if (status === 'error') {
        return COLOR.red;
      }
      if (index === progressIndex) {
        return COLOR.green;
      }
      return GREYSCALE.grey50;
    })();

    return (
      <Styled.StageWrapper key={title} data-testid={title}>
        <Styled.Dot color={dotColor}>
          {index <= progressIndex && (
            <FontAwesomeIcon
              icon={index < progressIndex ? 'check' : 'circle'}
              color={GREYSCALE.white}
            />
          )}
        </Styled.Dot>
        <Styled.StageTexts>
          <Styled.StageTitle bold={index === progressIndex} color={titleColor}>
            {title}
          </Styled.StageTitle>
          <Styled.StageMessage>{convertBbcode(message)}</Styled.StageMessage>
        </Styled.StageTexts>
      </Styled.StageWrapper>
    );
  };

  return (
    <>
      {!isMobile && (
        <Styled.BarsWrapper>
          <Styled.Bars splitIndex={progressIndex}>
            <Styled.Bar color={COLOR.green} startIndex={0} endIndex={progressIndex} />
            <Styled.Bar color={GREYSCALE.grey80} startIndex={progressIndex} endIndex={4} />
          </Styled.Bars>
        </Styled.BarsWrapper>
      )}
      <Styled.StagesWrapper>{stageDots.map((s, i) => renderSection(s, i))}</Styled.StagesWrapper>
    </>
  );
}
