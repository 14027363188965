import * as yup from 'yup';
import { HazardousMaterialsSubformValues } from './HazardousMaterialsSubform';

export const INITIAL_HAZARDOUS_MATERIALS_SUBFORM_VALUES: HazardousMaterialsSubformValues = {
  hazardousMaterialsEnabled: false,
};

export const hazardousMaterialsValidationSchema = () =>
  yup.object<HazardousMaterialsSubformValues>({
    hazardousMaterialsEnabled: yup.boolean().defined(),
  });
