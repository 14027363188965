import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import LinkedIconTooltip from '../LinkedIconTooltip';
import Section, { SectionEntries } from './Section';
import { TYPOGRAPHY } from '../../styles/typography';

type LabelDetailsSectionProps = {
  labelSize: string;
  labelFileFormat: string;
};
const Styled = {
  TooltipText: styled.span`
    text-align: center;
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
  `,
};

function LabelDetailsSection({ labelSize, labelFileFormat }: LabelDetailsSectionProps) {
  const entries: SectionEntries = [
    ['Label Size', `${labelSize.replace('SIZE_', '')}"`],
    ['Label Filetype', labelFileFormat.toUpperCase()],
  ];
  return (
    <Section
      title="Label Details"
      tooltip={
        <LinkedIconTooltip href="https://support.pirateship.com/en/articles/4140701-how-can-i-change-the-size-of-my-label">
          <Styled.TooltipText>
            Label size and format cannot be changed after purchase. Click the{' '}
            <FontAwesomeIcon icon="question-circle" /> below to learn more 👍
          </Styled.TooltipText>
        </LinkedIconTooltip>
      }
      entries={entries}
    />
  );
}

export default LabelDetailsSection;
