import type { HTMLAttributes, PropsWithChildren } from 'react';
import { LinkButton } from '../../components/Link';

type ClaimInsureShieldInsuranceLinkProps = {
  shipmentId: string;
} & HTMLAttributes<HTMLButtonElement>;

export default function ClaimInsureShieldInsuranceLink({
  shipmentId,
  children = 'File an Insurance Claim',
  ...props
}: PropsWithChildren<ClaimInsureShieldInsuranceLinkProps>) {
  const handleClaimClick = () => {
    window.open(`/insureshield/claims/redirect?shipment_id=${shipmentId}`);
  };

  return (
    <LinkButton {...props} onClick={handleClaimClick} data-testid="insureshield-portal">
      <span>{children}</span>
    </LinkButton>
  );
}
