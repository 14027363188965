import { useReactiveVar } from '@apollo/client';
import Modal from '../../components/modals/Modal';
import FlashMessages from '../../components/FlashMessages';
import { flashMessagesVar } from '../../apollo/cache';
import { clearFlashMessages } from '../../apollo/cache/flashMessages';
import { Row, Col, Container } from '../../components/layout/Grid';
import ProgressButton from '../../components/form/ProgressButton';
import PageLayoutSelect from './PageLayoutSelect';
import PageLoading from '../../components/loading/PageLoading';
import useWindowSize from '../../hooks/useWindowSize';
import downloadUrl from '../utils/downloadUrl';
import { useDownloadLabelByShipmentQuery } from '../operations/downloadLabelByShipment.query';
import ShareLink from './ShareLink';
import { useShareDownloadUrlByShipmentLazyQuery } from '../operations/shareDownloadUrlByShipment.query';
import PdfPreview from './PdfPreview';

type ShipmentPrintModalProps = {
  open: boolean;
  onClose: () => void;
  shipmentId: string;
};

export default function ShipmentPrintModal({ open, onClose, shipmentId }: ShipmentPrintModalProps) {
  const [, windowHeight] = useWindowSize();
  const [createShareLink, { data: shareLinkData }] = useShareDownloadUrlByShipmentLazyQuery({
    variables: { shipmentId },
  });
  const { data, loading, refetch, startPolling, stopPolling } = useDownloadLabelByShipmentQuery({
    variables: { shipmentId },
    skip: !open, // Run query once the modal is open
    notifyOnNetworkStatusChange: true, // Call onCompleted after each fetch
    onError: () => {
      stopPolling();
    },
    onCompleted: ({ downloadLabelByShipment: { status } }) => {
      switch (status) {
        case 'IDLE':
        case 'RUNNING':
          startPolling(1000);
          break;
        case 'ERROR':
        case 'FINISHED':
          stopPolling();
          break;
        default:
      }
    },
  });

  const flashMessages = useReactiveVar(flashMessagesVar);

  const onModalClose = () => {
    clearFlashMessages();
    onClose?.();
  };

  const renderPreview = () => {
    if (flashMessages.length) {
      return null;
    }
    if (
      !loading &&
      // correct status to show the print preview
      data !== undefined &&
      data.downloadLabelByShipment.pageLayout &&
      data.downloadLabelByShipment.fileFormat &&
      data.downloadLabelByShipment.url &&
      data.downloadLabelByShipment.status === 'FINISHED'
    ) {
      const { pageLayout, fileFormat, url } = data.downloadLabelByShipment;

      return (
        <>
          <Row>
            <Col md={12}>
              <PageLayoutSelect
                pageLayout={pageLayout}
                fileFormat={fileFormat}
                onPageLayoutChange={(newPageLayout) => {
                  refetch({
                    shipmentId,
                    pageLayout: newPageLayout,
                  });
                }}
              />
            </Col>
            <PdfPreview shipmentLabelFileFormat={fileFormat} url={url} />
          </Row>
          <Row>
            <Col md={12} spaceBelow>
              <ProgressButton
                data-testid="download-label-button"
                variant="success"
                fullWidth
                onClick={(event) => {
                  event.preventDefault();
                  downloadUrl(url);
                }}
              >
                Download Label
              </ProgressButton>
            </Col>

            <Col xl={12}>
              <ShareLink
                onClick={() => createShareLink()}
                url={shareLinkData?.shareDownloadUrlByShipment}
              />
            </Col>
          </Row>
        </>
      );
    }
    return <PageLoading height={`${windowHeight / 2}px`} color="white" />;
  };

  return (
    <Modal theme="dark" open={open} width={950} onClose={onModalClose}>
      <Container noGutter>
        <Row>
          <Col md={12}>
            <FlashMessages />
          </Col>
        </Row>
        {renderPreview()}
      </Container>
    </Modal>
  );
}
