import { CarrierKey } from '../gql/graphql';

type DataStub = {
  company: {
    activeCarriers: readonly CarrierKey[];
  };
  carriers: readonly {
    carrierKey: CarrierKey;
    packageTypes: readonly {
      packageTypeKey: string;
    }[];
  }[];
};

/**
 * This function converts packageTypeQuery data and into an appropriate list for dropdown selection:
 * it filters based on active carriers
 * if multiple carriers are selected, it removes duplicate generic "Parcel" and "SoftEnvelope" entries
 */
export default function getFilteredPackageTypes<D extends DataStub>(data: D) {
  return data.carriers
    .filter((car) => data.company.activeCarriers.includes(car.carrierKey))
    .map((car) => car.packageTypes)
    .flat()
    .reduce<D['carriers'][0]['packageTypes']>((prev, curr) => {
      // Don't add package type if it already is in the list
      if (prev.findIndex((pt) => pt.packageTypeKey === curr.packageTypeKey) > -1) {
        return prev;
      }

      return [...prev, curr];
    }, []);
}
