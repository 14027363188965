import convertBbcode from '../../utils/convertBbcode';
import { Row, Col } from '../../components/layout/Grid';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import getBatchRefundModalTitle from '../utils/getBatchRefundModalTitle';
import { BATCH_REFUND_COPY } from '../constants/refundSuccessMessages';

type BatchRefundModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationButtonProgress: boolean;
  canInstantRefundBatch?: boolean;
  isUpsOnlyBatch?: boolean;
  isUspsOnlyBatch?: boolean;
};

export default function BatchRefundModal({
  open,
  onCancel,
  onConfirm,
  confirmationButtonProgress,
  canInstantRefundBatch,
  isUpsOnlyBatch,
  isUspsOnlyBatch,
}: BatchRefundModalProps) {
  const getRefundContent = () => {
    if (canInstantRefundBatch) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(BATCH_REFUND_COPY.instant.description)}
        </Col>
      );
    }
    if (isUpsOnlyBatch) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(BATCH_REFUND_COPY.ups.description)}
        </Col>
      );
    }
    if (isUspsOnlyBatch) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(BATCH_REFUND_COPY.usps.description)}
        </Col>
      );
    }
    return (
      <>
        <Col md={12} spaceBelow>
          <strong>{BATCH_REFUND_COPY.ups.title}</strong>
        </Col>
        <Col md={12} spaceBelow>
          {convertBbcode(BATCH_REFUND_COPY.ups.description)}
        </Col>
        <Col md={12} spaceBelow>
          <strong>{BATCH_REFUND_COPY.usps.title}</strong>
        </Col>
        <Col md={12} spaceBelow>
          {convertBbcode(BATCH_REFUND_COPY.usps.description)}
        </Col>
      </>
    );
  };

  return (
    <ConfirmationModal
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmationButtonProgress={confirmationButtonProgress}
      confirmationButtonLabel="Continue"
      title={getBatchRefundModalTitle(!!canInstantRefundBatch, !!isUpsOnlyBatch, !!isUspsOnlyBatch)}
    >
      <Row>{getRefundContent()}</Row>
      <Row>
        <Col md={12}>Do you want to continue?</Col>
      </Row>
    </ConfirmationModal>
  );
}
