import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import batchProcessStatusQuery from '../../operations/queries/batchProcessStatus';
import { BATCH_PROCESS_POLL_INTERVAL } from '../../constants';
import useInterval from '../../hooks/useInterval';
import { PageContainer } from '../../components/layout/Grid';
import LoadingAnimation, { RunningProcess } from '../../components/pages/ship/LoadingAnimation';
import PageLoading from '../../components/loading/PageLoading';
import { setFlashMessage } from '../../apollo/cache/flashMessages';
import isSingleCarrierBatch from '../../utils/isSingleCarrierBatch';
import getBatchRefundSuccessMessage from '../utils/getBatchRefundSuccessMessage';

type BatchRefundingPageProps = {
  bridgeBatchId?: string;
  onRefundComplete?: () => void;
};

export default function BatchRefundingPage({
  bridgeBatchId,
  onRefundComplete,
}: BatchRefundingPageProps) {
  const { batchId } = useParams();
  const [getRunningProcess, { data }] = useLazyQuery(batchProcessStatusQuery);
  const queryBatchId = batchId || bridgeBatchId;
  useInterval(() => {
    if (queryBatchId) {
      getRunningProcess({
        variables: {
          id: queryBatchId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, BATCH_PROCESS_POLL_INTERVAL);

  useEffect(() => {
    // maybe we need here more status for navigation
    if (
      data &&
      ((data.batch.runningProcess === null && data.batch.status === 'REFUNDED') ||
        data.batch.status === 'PARTLY_REFUNDED' ||
        data.batch.status === 'REFUND_PENDING')
    ) {
      const { isUpsOnlyBatch, isUspsOnlyBatch } = isSingleCarrierBatch(data?.batch);
      setFlashMessage(
        getBatchRefundSuccessMessage(
          data?.batch.canInstantRefundBatch ?? false,
          isUspsOnlyBatch,
          isUpsOnlyBatch,
        ),
        'success',
        -1,
        true,
      );
      onRefundComplete?.();
    }
  }, [data, onRefundComplete]);

  if (!data || !data.batch.runningProcess) {
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );
  }

  return <LoadingAnimation runningProcess={data.batch.runningProcess as RunningProcess} />;
}
