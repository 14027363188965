import { gql } from '../../gql';

const disputeAdjustmentsMutation = gql(/* GraphQL */ `
  mutation DisputeAdjustmentsMutation($shipmentId: ID!, $disputeReason: String!) {
    disputeAdjustments(shipmentId: $shipmentId, disputeReason: $disputeReason) {
      id
      disputedAt
      disputeStatus
    }
  }
`);

export default disputeAdjustmentsMutation;
