import { SHIPMENT_REFUND_COPY } from '../constants/refundSuccessMessages';

export default function getShipmentRefundModalTitle(
  canInstantRefundShipment: boolean,
  isUpsShipment: boolean,
) {
  if (canInstantRefundShipment) {
    return SHIPMENT_REFUND_COPY.instant.title;
  }
  if (isUpsShipment) {
    return SHIPMENT_REFUND_COPY.ups.title;
  }
  return SHIPMENT_REFUND_COPY.usps.title;
}
