import * as yup from 'yup';
import { StringSchema } from 'yup';
import { AddressSubformValues } from './AddressSubform';

const errorMessages = {
  phone: 'Please specify a valid phone number',
  fullNameOrCompany: 'Either name or company need to be specified',
};

const phoneRegex = {
  US: /^(?:\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/, // only allow this format: (212) 555-1232, rest is handled by PhoneTextField
  INTERNATIONAL: /[+0-9\s\-()]+$/, // loose regex incl. optional +, digits, spaces, hyphens, parentheses
};

export const INITIAL_ADDRESS_SUBFORM_VALUES: AddressSubformValues = {
  fullName: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  regionCode: '',
  postcode: '',
  countryCode: 'US',
  phone: '',
};

export const addressValidationSchema = ({
  nameRequired,
  fullNameSchema = yup.string().required(),
  companyNameSchema = yup.string().defined().default(''),
  phone,
  address1Schema = yup.string().required(),
}: {
  nameRequired?: boolean;
  fullNameSchema?: StringSchema<string>;
  companyNameSchema?: StringSchema<string>;
  phone?: Uppercase<keyof typeof phoneRegex>;
  address1Schema?: StringSchema<string>;
} = {}) =>
  yup.object<AddressSubformValues>().shape(
    {
      fullName: nameRequired
        ? fullNameSchema
        : yup
            .string()
            .when(['fullName', 'company'], {
              is: (fullName, company) => !fullName?.length && !company?.length,
              then: () => yup.string().required(errorMessages.fullNameOrCompany),
            })
            .default(''),
      company: nameRequired
        ? companyNameSchema
        : yup
            .string()
            .when(['fullName', 'company'], {
              is: (fullName, company) => !fullName?.length && !company?.length,
              then: () => yup.string().required(errorMessages.fullNameOrCompany),
            })
            .default(''),
      address1: address1Schema,
      address2: yup.string().defined().default(''),
      city: yup.string().required(),
      regionCode: yup.string().required(),
      postcode: yup.string().usZip().required(),
      countryCode: yup.string().required(),
      phone: phone
        ? yup
            .string()
            .required(errorMessages.phone)
            .matches(phoneRegex[phone] || phoneRegex.INTERNATIONAL, errorMessages.phone)
        : yup.string().notRequired(),
    },
    // explicitly allow [a => b] dependencies in the 'when' conditions above
    [
      ['company', 'company'],
      ['fullName', 'fullName'],
      ['company', 'fullName'],
      ['fullName', 'company'],
    ],
  );
