import Checkbox from '../../../form/Checkbox';
import FormControl from '../../../form/FormControl';

type StaySignedInCheckboxProps = {
  name?: string;
};

export default function StaySignedInCheckbox({ name }: StaySignedInCheckboxProps) {
  return (
    <FormControl
      name={name ?? 'remember'}
      as={Checkbox}
      label="Stay signed in"
      type="checkbox"
      data-testid="staySignedInCheckbox"
    />
  );
}
