import styled from '@emotion/styled';
import useAriaAnnouncer from '../../hooks/useAriaAnnouncer';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import BaseLoading, { BaseLoadingProps } from './BaseLoading';

const Styled = {
  Wrapper: styled.main<PageLoadingProps>`
    position: relative;
    width: 100%;
    height: ${({ height }) => height || '70vh'};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${MEDIA_QUERY.smMin}) {
      height: ${({ height }) => height || '80vh'};
    }
  `,
};

type PageLoadingProps = BaseLoadingProps & { height?: string };

function PageLoading({ size = 30, height, color = 'black' }: PageLoadingProps) {
  const announce = useAriaAnnouncer();
  announce('Loading');

  return (
    <Styled.Wrapper height={height} data-testid="page-loading">
      <BaseLoading size={size} color={color} />
    </Styled.Wrapper>
  );
}

export default PageLoading;
