/**
  removes any non digit except '+' &
 */

const sanitizePhoneNumber = (phone: string | number | undefined | null): string | undefined => {
  if (!phone) return undefined;

  const sanitized = phone.toString().replace(/[^\d+]/g, '');

  return sanitized.length ? sanitized : undefined;
};
export default sanitizePhoneNumber;
