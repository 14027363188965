import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DropdownSelectOption } from '../components/form/DropdownSelect';
import { NEW_WAREHOUSE_VALUE } from '../constants';
import createCountryMap from '../utils/createCountryMap';
import formatAddress, { Address } from '../utils/formatAddress';

// not a typo, the object you give a function is also called options. blöd
type UseWarehouseOptionsOptions = {
  data?: {
    countries: ReadonlyArray<{ id: string; countryCode: string; name: string }>;
    company: {
      warehouses: ReadonlyArray<{
        useOriginAsReturnAddress: boolean;
        originAddress: Address;
        returnAddress: Address;
        id: string;
        title: string;
      }>;
    };
  };
  lastOptionAllowsCreation?: boolean;
};

// warehouse options used on all pickup pages
export default function useWarehouseOptions({
  data,
  lastOptionAllowsCreation,
}: UseWarehouseOptionsOptions): DropdownSelectOption<string>[] {
  const warehouseOptions = useMemo(() => {
    if (!data) {
      return [];
    }

    const countryMap = createCountryMap(data.countries);

    const options = data.company.warehouses.map((warehouse): DropdownSelectOption<string> => {
      const addressDelimiter = ', ';

      const description = warehouse.useOriginAsReturnAddress ? (
        formatAddress(warehouse.originAddress, countryMap, addressDelimiter)
      ) : (
        <>
          <strong>Physical Address:</strong>{' '}
          {formatAddress(warehouse.originAddress, countryMap, addressDelimiter)}
          <br />
          <strong>Return Address:</strong>{' '}
          {formatAddress(warehouse.returnAddress, countryMap, addressDelimiter)}
        </>
      );
      return {
        value: warehouse.id,
        title: warehouse.title,
        description,
      };
    });

    // the final option is to navigate to the settings page to create a new address, or create a new one "on-site"
    // the navigation is handled in the onChange function in the dropdown select form control

    const title = (() => {
      if (lastOptionAllowsCreation) return 'Create New Ship From Address';
      if (options.length === 0) return "You don't have a Saved Ship From Address";
      return "Don't use Saved Ship From Address";
    })();

    const description = lastOptionAllowsCreation ? (
      'Create New Ship From Address'
    ) : (
      <>
        Add a new Ship From Address in <Link to="/settings/shipfrom">Settings</Link>, then start the
        pickup request over
      </>
    );

    options.push({
      value: NEW_WAREHOUSE_VALUE,
      title,
      description,
    });

    return options;
  }, [data, lastOptionAllowsCreation]);
  return warehouseOptions;
}
