import ServiceSummaryTable from '../../components/serviceSummaryTable/StyledServiceSummaryTable';
import { CarrierIcon } from '../../components/Icon';
import type { ShipmentForServiceDetails } from '../types/ShipmentServiceTypes';

export type NullableTitle = {
  title: string;
} | null;

type ShipmentServiceSummaryServiceTitleProps = {
  shipmentServiceDetails: ShipmentForServiceDetails;
};

export default function ShipmentServiceSummaryServiceTitle({
  shipmentServiceDetails,
}: ShipmentServiceSummaryServiceTitleProps) {
  return (
    <ServiceSummaryTable.ServiceTitleWrapper>
      <CarrierIcon
        carrierKey={shipmentServiceDetails.carrierKey.toLocaleUpperCase()}
        mailClassKey={shipmentServiceDetails.mailClassKey}
      />
      {shipmentServiceDetails.mailClassOriginal?.title !==
        shipmentServiceDetails.mailClass?.title && (
        <ServiceSummaryTable.Strikethrough>
          {shipmentServiceDetails.mailClassOriginal?.title}
        </ServiceSummaryTable.Strikethrough>
      )}
      {shipmentServiceDetails.mailClass?.title}{' '}
      {shipmentServiceDetails.carrierKey === 'usps' && (
        <>
          {` • `}
          {shipmentServiceDetails.zoneOriginal !== shipmentServiceDetails.zone && (
            <ServiceSummaryTable.Strikethrough>
              {`Zone ${shipmentServiceDetails.zoneOriginal}`}
            </ServiceSummaryTable.Strikethrough>
          )}
          {`Zone ${shipmentServiceDetails.zone}`}
        </>
      )}
      {shipmentServiceDetails.status.includes('RETURN') ? ` • Return Label` : ''}
    </ServiceSummaryTable.ServiceTitleWrapper>
  );
}
