import * as yup from 'yup';
import { addressValidationSchema } from '../../../subforms/addressSubformUtils';
import { SignupFormValues } from './SignupFormValues';

export const commonSignupSchema = (isEu: boolean) => ({
  email: yup.string().max(254).emailAddress().required(),
  password: yup.string().min(12).max(200).required(),
  useOriginAsReturnAddress: yup.boolean().defined(),
  agree: isEu ? yup.boolean().defined().isTrue() : yup.boolean().defined(),
});

export const originAddressSchema = () =>
  addressValidationSchema({
    nameRequired: true,
    fullNameSchema: yup
      .string()
      .matches(/ /, 'USPS requires your full name')
      .matches(/^([^0-9]*)$/, 'Please use letters only')
      .required(),
    companyNameSchema: yup
      .string()
      .when('company', {
        is: (company) => !!company?.length,
        then: () =>
          yup.string().defined().min(3).matches(/[a-z]/i, 'USPS requires a valid company name'),
      })
      .default(''),
    address1Schema: yup
      .string()
      .test(
        'PO Box not allowed',
        'Physical address required; use your PO Box in the Return Address below',
        (value) => !/p(ost)?[ |.]*o(ffice)?[ |.]*(box)?[ 0-9]*[^[a-z ]]*/i.test(value ?? ''),
      )
      .required(),
    phone: 'US',
  }).required();

export const returnAddressSchema = () =>
  addressValidationSchema()
    .defined()
    .when('useOriginAsReturnAddress', (useOriginAsReturnAddress: boolean, schema: any) =>
      useOriginAsReturnAddress ? yup.object() : schema.required(),
    );

export const initSignupValidationSchema = ({
  isEu = false,
  withAddressValidation = true,
}: {
  isEu: boolean;
  withAddressValidation?: boolean;
}) =>
  yup.object<SignupFormValues>({
    ...commonSignupSchema(isEu),
    ...(withAddressValidation
      ? {
          originAddress: originAddressSchema(),
          returnAddress: returnAddressSchema(),
        }
      : {
          originAddress: yup.object(),
          returnAddress: yup.object(),
        }),
  });
