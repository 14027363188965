import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createWarehouseMutation = gql(/* GraphQL */ `
  mutation CreateWarehouseMutation(
    $title: String!
    $saveAddressForReuse: Boolean!
    $useOriginAsReturnAddress: Boolean!
    $originAddress: AddressInput!
    $returnAddress: AddressInput
  ) {
    createWarehouse(
      title: $title
      useOriginAsReturnAddress: $useOriginAsReturnAddress
      originAddress: $originAddress
      returnAddress: $returnAddress
      saveAddressForReuse: $saveAddressForReuse
    ) {
      ...WarehouseFragment
    }
  }
`);

export const useCreateWarehouseMutation = () =>
  useMutation(createWarehouseMutation, {
    // Add newly created entry to list
    update(cache, { data }) {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateWarehousesAfterCreateQuery {
              company {
                id
                warehouses {
                  ...WarehouseFragment
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          return {
            company: {
              ...cacheResult.company,
              warehouses: [...cacheResult.company.warehouses, data.createWarehouse],
            },
          };
        },
      );
    },
  });
