import * as yup from 'yup';
import { DimensionsConfig, WeightConfig } from '../../validation/validators';
import { CustomsFormSubformValues } from './CustomsFormSubform';
import { PackageSubformValues, PackageType } from './PackageSubform';

export const INITIAL_PACKAGE_SUBFORM_VALUES: PackageSubformValues = {
  packageTypeKey: 'Parcel',
  dimensionX: '',
  dimensionY: '',
  dimensionZ: '',
  weightPounds: 0,
  weightOunces: 0,
};

/**
 * Returns "Parcel" if the package type key is not found in the package types list.
 * This can happen, if a carrier decides to remove a package type, but users still have this package type saved in their presets.
 */
export const getInitialPackageTypeKey = (
  packageTypes: readonly PackageType[] | undefined,
  packageTypeKey: string,
) => {
  const noPackageTypeFound = !packageTypes?.find(
    (packageType) => packageType.packageTypeKey === packageTypeKey,
  );

  if (noPackageTypeFound) {
    return 'Parcel';
  }
  return packageTypeKey;
};

export const packageValidationSchema = (
  boundaries: DimensionsConfig & WeightConfig,
  packageTypes: ReadonlyArray<PackageType>,
  customsItems: CustomsFormSubformValues | undefined, // if in a form with customsItems, pass them in here
) => {
  const getPackageType = (packageTypeKey: string) =>
    packageTypes.find((pt) => pt.packageTypeKey === packageTypeKey);

  return yup.object<PackageSubformValues>({
    packageTypeKey: yup.string().required(),
    dimensionX: yup.string().when('packageTypeKey', (packageTypeKey, schema: yup.StringSchema) => {
      const packageType = getPackageType(packageTypeKey);
      return packageType?.dimensionsRequired ? schema.required().packageLength() : schema;
    }),
    dimensionY: yup.string().when('packageTypeKey', (packageTypeKey, schema: yup.StringSchema) => {
      const packageType = getPackageType(packageTypeKey);
      return packageType?.dimensionsRequired ? schema.required().packageLength() : schema;
    }),
    dimensionZ: yup.string().when('packageTypeKey', (packageTypeKey, schema: yup.StringSchema) => {
      const packageType = getPackageType(packageTypeKey);
      return packageType?.heightRequired ? schema.required().packageLength() : schema;
    }),
    weightPounds: yup.number().required().min(0).default(0),
    weightOunces: yup.number().required().min(0).default(0),
    combinedDimensions: yup
      .object()
      .when('packageTypeKey', (packageTypeKey, schema: yup.ObjectSchema) => {
        const packageType = getPackageType(packageTypeKey);
        if (packageType?.dimensionsRequired) {
          return packageType.heightRequired
            ? schema.packageDimensions3d(boundaries)
            : schema.packageDimensions2d(boundaries);
        }
        return schema;
      }),
    combinedWeight: yup
      .object()
      .when('packageTypeKey', (packageTypeKey, schema: yup.ObjectSchema) => {
        const packageType = getPackageType(packageTypeKey);
        if (packageType?.weightRequired) {
          return customsItems
            ? schema.packageWeight(boundaries).customsWeightsPackageWeight(customsItems)
            : schema.packageWeight(boundaries);
        }
        return schema;
      }),
  });
};
