import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createShipmentPresetMutation = gql(/* GraphQL */ `
  mutation CreateShipmentPresetMutation($default: Boolean!, $shipmentPreset: ShipmentPresetInput!) {
    createShipmentPreset(default: $default, shipmentPreset: $shipmentPreset) {
      shipmentPreset {
        ...ShipmentPresetFragment
      }
      default
    }
  }
`);

export const useCreateShipmentPresetMutation = () =>
  useMutation(createShipmentPresetMutation, {
    // Add newly created entry to list
    update(cache, { data }) {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateShipmentPresetsAfterCreateQuery {
              company {
                id
                shipmentPresets {
                  ...ShipmentPresetFragment
                }
                settings {
                  defaultShipmentPresetId
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          return {
            company: {
              ...cacheResult.company,
              shipmentPresets: [
                ...cacheResult.company.shipmentPresets,
                data.createShipmentPreset.shipmentPreset,
              ],
              settings: {
                ...cacheResult.company.settings,
                defaultShipmentPresetId: data.createShipmentPreset.default
                  ? data.createShipmentPreset.shipmentPreset.id
                  : cacheResult.company.settings.defaultShipmentPresetId,
              },
            },
          };
        },
      );
    },
  });
