import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion, useAnimationControls } from 'framer-motion';
import useIsMobile from '../../../hooks/useIsMobile';
import { BORDER_RADIUS } from '../../../styles/borders';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import { GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import blockForwardingProps from '../../../utils/blockForwardingProps';
import secondsToString from '../../../utils/secondsToString';
import loadingIcons from '../../../assets/loading';
import useWindowSize from '../../../hooks/useWindowSize';
import withOpacity from '../../../utils/withOpacity';

type TalkingBubbleProps = {
  right?: boolean;
};

const Styled = {
  Wrapper: styled.div`
    position: relative;
    margin: -${SPACING.xxl};
  `,
  ProcessWrapper: styled.div`
    position: absolute;
    display: block;
    top: 27vh;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    text-align: center;
    background-color: ${withOpacity(GREYSCALE.white, 0.9)};
    border-radius: ${BORDER_RADIUS.lg};
    z-index: 9999;
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      top: 30vh;
      left: 4%;
      right: 4%;
      transform: translateX(0);
    }
  `,
  ProcessTitleWrapper: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.xl};
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
  `,
  ProcessSubtitleWrapper: styled.div`
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    color: ${GREYSCALE.grey50};
  `,
  ProcessHint: styled.div`
    margin: ${SPACING.none} ${SPACING.sm};
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
  Ocean: styled(motion.div)`
    position: absolute;
    bottom: -3000px;
    z-index: 3;
    left: 0;
    right: 0;
    width: 100%;
  `,
  LoadingWrapper: styled.div`
    height: 100vh;
    max-width: 100%;
    min-height: 1000px;
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      margin-top: -15px;
      min-height: 568px;
    }
  `,
  CloudsWrapper: styled.div`
    position: absolute;
    width: 100%;
    height: 50vh;
    top: -50vh;
  `,
  Clouds: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  Main: styled.main`
    position: relative;
    height: 100px;
    background-image: url('${loadingIcons.wave}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: ${MEDIA_QUERY.smMin}) {
      height: 60px;
    }
  `,
  AnimationContainer: styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  `,
  TalkingWrapper: styled(motion.div)`
    position: absolute;
    top: 10%;
    width: 100%;
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      top: 35%;
    }
  `,
  TalkingBubble: styled('div', blockForwardingProps('right'))<TalkingBubbleProps>`
    display: block;
    position: relative;
    max-width: 300px;
    height: auto;
    margin-left: 10%;

    ${({ right }) =>
      right &&
      css`
        margin-right: 15%;
        margin-left: auto;
        text-align: right;
        margin-top: 8%;
      `}

    & :after {
      content: '';
      display: block;
      position: absolute;
      border: 1px solid #000;
      border-radius: 1px;
      height: 12px;
      transform: rotate(45deg);
      left: 0;
      ${({ right }) =>
        right &&
        css`
          transform: rotate(-64deg);
          right: -20px;
          left: auto;
          bottom: 5%;
        `}
    }
  `,
  TalkingText: styled(motion.p)`
    font-family: ${TYPOGRAPHY.font.secondary.fontFamily};
    position: relative;
    padding: 0;
  `,
  Wave: styled.img`
    position: relative;
    top: 0;
    left: 0;
  `,
  FooterWrapper: styled.div`
    height: 24%;
    display: flex;
    background-image: url('${loadingIcons.footer}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      height: 20%;
      background-size: 300%;
      background-position: bottom center;
    }
  `,
  Content: styled.div`
    position: relative;
    height: 60%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  `,
  Diver: styled(motion.img)`
    position: absolute;
    top: calc(75% - 88px);
    left: calc(50% - 88px);
    width: calc(358px / 2);
    height: calc(355px / 2);
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      top: calc(60% - 60px);
      left: calc(50% - 60px);
      width: 119.3333333333px;
      height: 118.3333333333px;
    }
  `,
  Box1: styled(motion.img)`
    position: absolute;
    top: 60%;
    left: 5%;
    width: calc(275px / 2);
    height: calc(78px / 2);
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      top: 60%;
      left: 5%;
      width: 91.6666666667px;
      height: 26px;
    }
  `,
  Box2: styled(motion.img)`
    position: absolute;
    top: 42%;
    left: 28%;
    width: calc(222px / 2);
    height: calc(90px / 2);
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      display: none;
    }
  `,
  Box3: styled(motion.img)`
    position: absolute;
    top: 45%;
    right: 5%;
    width: calc(213px / 2);
    height: calc(115px / 2);
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      top: 45%;
      right: 5%;
      width: 71px;
      height: 38.3333333333px;
    }
  `,
  Bubble1: styled(motion.img)`
    position: absolute;
    top: 90%;
    left: 12%;
    width: 3%;
  `,
  Bubble2: styled(motion.img)`
    position: absolute;
    top: 85%;
    left: 21%;
    width: 4%;
  `,
  Bubble3: styled(motion.img)`
    position: absolute;
    top: 98%;
    left: 4%;
    width: 4%;
  `,
  Bubble4: styled(motion.img)`
    position: absolute;
    top: 80%;
    right: 5%;
    width: 5%;
  `,
  Bubble5: styled(motion.img)`
    position: absolute;
    top: 95%;
    right: 11%;
    width: 3.5%;
  `,
  Bubble6: styled(motion.img)`
    position: absolute;
    top: 105%;
    right: 20%;
    width: 3%;
  `,
  Cloud1: styled(motion.img)`
    position: absolute;
    top: 16%;
    left: 5%;
    width: calc(100vw / 6);
    max-width: 230px;
    height: auto;
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      width: 20vw;
    }
  `,
  Cloud2: styled(motion.img)`
    position: absolute;
    top: 6%;
    left: 42%;
    width: calc(100vw / 6);
    max-width: 230px;
    height: auto;
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      width: 20vw;
    }
  `,
  Cloud3: styled(motion.img)`
    position: absolute;
    top: 13%;
    right: 3%;
    width: calc(100vw / 6);
    max-width: 230px;
    height: auto;
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      width: 20vw;
    }
  `,
  Fish1: styled(motion.img)`
    position: absolute;
    opacity: 0;
    left: -20%;
    width: 78.5px;
    height: 72.5px;
  `,
  Fish2: styled(motion.img)`
    position: absolute;
    opacity: 0;
    right: -35%;
    width: calc(165px / 2);
    height: calc(126px / 2);
  `,
  Fish3: styled(motion.img)`
    position: absolute;
    bottom: 17%;
    right: 21%;
    width: calc(177px / 2);
    height: calc(153px / 2);
  `,
};

type Joke = {
  question: string;
  answer: string;
};

const jokes: Joke[] = [
  {
    question: 'What do you call a pirate with two eyes, two hands, and two legs?',
    answer: 'A begin-ARRrrr!',
  },
  { question: 'Have you ever heard a good pirate joke?', answer: 'Neither have ayyye!' },
  { question: "What's the best way to buy shipping labels?", answer: "With y'er Aye-Phone" },
  { question: 'To err is human…', answer: 'To arr is pirate!' },
  {
    question: 'Why does it take pirates so long to learn the alphabet?',
    answer: 'Because they can spend years at C!',
  },
  {
    question: 'Why do seagulls fly over the sea?',
    answer: "Because if they flew over the bay, they'd be bagels!",
  },
  {
    question: 'Why is being a pirate addictive?',
    answer: "They say once y'ee lose y'er first hand, y'ee get hooked!",
  },
  {
    question: 'How do pirates know that they are pirates?',
    answer: 'They think, therefore they ARRRR!',
  },
  { question: "What is a pirate's least favourite vegetable?", answer: 'Leeks!' },
  { question: 'What do pirates hate the most?', answer: 'A broken heaARRRRRt!' },
  {
    question: 'What do you get when you cross a pirate with a zucchini?',
    answer: 'A squashbuckler!',
  },
  { question: 'Why do all pirates have eyepatches?', answer: 'Chuck Norris' },
  {
    question: "Why don't pirates like computers?",
    answer: "They're scared of getting cARRRRpal tunnel!",
  },
  { question: 'What do pirates take for indigestion?', answer: 'Yo ho ho and a bottle of Tums' },
  {
    question: 'Where did the pirate learn to draw his sword?',
    answer: 'At the ARRRRrrt Institute',
  },
  {
    question: "What did the pirate say when he couldn't find his ship?",
    answer: "Dude, where's my ship?",
  },
  { question: "What's a pirate's favorite play in basketball?", answer: 'A hook-shot!' },
  { question: "What does a pirate's dog say?", answer: 'ARRRF!' },
  { question: "What's a pirate's favorite vegetable?", answer: 'ARRRrsparagus!' },
  {
    question: 'How did the pirate get their postage so cheaply?',
    answer: 'They bought it on sail.',
  },
  { question: 'What has 8 legs, 8 arms and 8 eyes?', answer: '8 pirates!' },
  { question: "What do y'ee call a pirate with two eyes and two legs?", answer: 'A beginARRRrr!' },
  { question: 'What did the ocean say to the pirate?', answer: 'Nothing, it just waved.' },
  { question: "What's orange and sounds like a parrot?", answer: 'A carrot.' },
  {
    question: 'What are the 10 letters of the pirate alphabet?',
    answer: 'I, I, R and the seven C’s',
  },
  {
    question: 'What’s the difference between a pirate and a farmer?',
    answer: 'A pirate buries his treasure, but a farmer treasures his berries.',
  },
  {
    question: 'How do pirates keep up to date on Postal Regulations?',
    answer: 'They watch SEA-SPAN!',
  },
];

export type RunningProcess = {
  itemsInProgressCount: number;
  itemsTotalCount: number;
  processKey: string;
  progressPercentage: number;
  progressTitle: string;
  secondsLeft: number;
  status: string;
};

type LoadingAnimationProps = {
  runningProcess: RunningProcess;
};

export default function LoadingAnimation({ runningProcess }: LoadingAnimationProps) {
  const { progressTitle, progressPercentage, itemsInProgressCount, itemsTotalCount, secondsLeft } =
    runningProcess;

  const [joke] = useState(jokes[Math.floor(Math.random() * jokes.length)]);
  const { isMobile } = useIsMobile();
  const [, windowHeight] = useWindowSize();

  const oceanAnimationControls = useAnimationControls();
  const cloud1AnimationControls = useAnimationControls();
  const cloud2AnimationControls = useAnimationControls();
  const cloud3AnimationControls = useAnimationControls();
  const box1AnimationControls = useAnimationControls();
  const box2AnimationControls = useAnimationControls();
  const box3AnimationControls = useAnimationControls();
  const diverAnimationControls = useAnimationControls();
  const bubble1AnimationControls = useAnimationControls();
  const bubble2AnimationControls = useAnimationControls();
  const bubble3AnimationControls = useAnimationControls();
  const bubble4AnimationControls = useAnimationControls();
  const bubble5AnimationControls = useAnimationControls();
  const bubble6AnimationControls = useAnimationControls();
  const fish1AnimationControls = useAnimationControls();
  const fish2AnimationControls = useAnimationControls();
  const fish3AnimationControls = useAnimationControls();
  const questionAnimationControls = useAnimationControls();
  const answerAnimationControls = useAnimationControls();

  useEffect(() => {
    const top = windowHeight - 50 - ((windowHeight - 50) * progressPercentage) / 100;
    if (document.visibilityState !== 'hidden') {
      oceanAnimationControls.start({
        top,
        transition: {
          delay: 1,
          ease: 'easeInOut',
        },
      });
    }
  }, [oceanAnimationControls, progressPercentage, windowHeight]);

  useEffect(() => {
    // stop all animations
    cloud1AnimationControls.stop();
    cloud2AnimationControls.stop();
    cloud3AnimationControls.stop();
    box1AnimationControls.stop();
    box2AnimationControls.stop();
    box3AnimationControls.stop();
    diverAnimationControls.stop();
    bubble1AnimationControls.stop();
    bubble2AnimationControls.stop();
    bubble3AnimationControls.stop();
    bubble4AnimationControls.stop();
    bubble5AnimationControls.stop();
    bubble6AnimationControls.stop();
    fish1AnimationControls.stop();
    fish2AnimationControls.stop();
    fish3AnimationControls.stop();
    questionAnimationControls.stop();
    answerAnimationControls.stop();

    // start animation
    cloud1AnimationControls.start({
      x: [0, -30],
      y: [0, -4],
      transition: {
        ease: 'easeInOut',
        duration: 4,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    });
    cloud2AnimationControls.start({
      x: [0, -30],
      y: [0, -4],
      transition: {
        ease: 'easeInOut',
        duration: 5,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    });
    cloud3AnimationControls.start({
      x: [0, -30],
      y: [0, -4],
      transition: {
        ease: 'easeInOut',
        duration: 6,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    });
    box1AnimationControls.start({
      x: [0, -5],
      y: [0, -7],
      rotate: [0, 2],
      transition: {
        ease: 'easeInOut',
        duration: 1.5,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    });
    box3AnimationControls.start({
      x: [0, -5],
      y: [0, -7],
      rotate: [0, 2],
      transition: {
        ease: 'easeInOut',
        duration: 1.8,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    });
    // start animation only if not mobile
    if (!isMobile) {
      box2AnimationControls.start({
        x: [0, -5],
        y: [0, -7],
        rotate: [0, 2],
        transition: {
          ease: 'easeInOut',
          duration: 1.7,
          repeat: Infinity,
          repeatType: 'reverse',
        },
      });
      diverAnimationControls.start({
        x: [0, -3],
        y: [0, -3],
        rotate: [-2, 2],
        transition: {
          ease: 'easeInOut',
          duration: 2.2,
          repeat: Infinity,
          repeatType: 'reverse',
        },
      });
      bubble1AnimationControls.start({
        x: [0, 20, 12, 0, 18, 8],
        y: [0, -40, -80, -110, -160, -190],
        opacity: [0, 1, 1, 0.8, 0.8, 0],
        transition: { duration: 6.1, ease: 'easeInOut', repeat: Infinity, repeatType: 'loop' },
      });
      bubble2AnimationControls.start({
        x: [0, -30, -18, 0, -24, -16],
        y: [0, -40, -80, -110, -160, -190],
        opacity: [0, 1, 1, 0.8, 0.8, 0],
        transition: { duration: 6.2, ease: 'easeInOut', repeat: Infinity, repeatType: 'loop' },
      });
      bubble3AnimationControls.start({
        x: [0, -30, -18, 0, -24, -16],
        y: [0, -40, -80, -110, -160, -190],
        opacity: [0, 1, 1, 0.8, 0.8, 0],
        transition: { duration: 6.3, ease: 'easeInOut', repeat: Infinity, repeatType: 'loop' },
      });
      bubble4AnimationControls.start({
        x: [0, 20, 12, 0, 18, 8],
        y: [0, -40, -80, -110, -150, -190],
        opacity: [0, 1, 1, 0.8, 0.8, 0],
        transition: { duration: 6.1, ease: 'easeInOut', repeat: Infinity, repeatType: 'loop' },
      });
      bubble5AnimationControls.start({
        x: [0, -30, -18, 0, -24, -16],
        y: [0, -40, -80, -110, -160, -190],
        opacity: [0, 1, 1, 0.8, 0.8, 0],
        transition: { duration: 6.2, ease: 'easeInOut', repeat: Infinity, repeatType: 'loop' },
      });
      bubble6AnimationControls.start({
        x: [0, -30, -18, 0, -24, -16],
        y: [0, -30, -50, -80, -95, -110],
        opacity: [0, 1, 1, 0.8, 0.8, 0],
        transition: { duration: 6.2, ease: 'easeInOut', repeat: Infinity, repeatType: 'loop' },
      });

      fish1AnimationControls.start({
        x: [-1000, 0],
        opacity: [0, 1],
        transition: { duration: 3, ease: 'anticipate', delay: 1 },
      });
      fish1AnimationControls.start({
        x: [0, -5],
        y: [0, -7],
        rotate: [0, 2],
        transition: {
          ease: 'easeInOut',
          duration: 1.7,
          repeat: Infinity,
          repeatType: 'reverse',
        },
      });

      fish2AnimationControls.start({
        x: [2000, 0],
        opacity: [0, 1],
        transition: { duration: 3, ease: 'anticipate', delay: 2 },
      });
      fish2AnimationControls.start({
        x: [0, -5],
        y: [0, -7],
        rotate: [0, 2],
        transition: {
          ease: 'easeInOut',
          duration: 1.5,
          repeat: Infinity,
          repeatType: 'reverse',
        },
      });

      fish3AnimationControls.start({
        x: [0, -5],
        y: [0, -7],
        rotate: [0, 2],
        transition: { duration: 1.7, ease: 'easeInOut', repeat: Infinity, repeatType: 'reverse' },
      });

      questionAnimationControls.start({
        opacity: [0, 1],
        transition: {
          duration: 1,
          ease: 'anticipate',
          delay: 5,
        },
      });
      answerAnimationControls.start({
        opacity: [0, 1],
        transition: {
          duration: 1,
          ease: 'anticipate',
          delay: 7,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <Styled.Wrapper>
      <Styled.ProcessWrapper>
        <Styled.ProcessTitleWrapper role="status">
          <span>{progressTitle} — </span>
          <span>{progressPercentage}% </span>
          <span>complete</span>
        </Styled.ProcessTitleWrapper>
        <Styled.ProcessSubtitleWrapper>
          <span>{`${itemsInProgressCount}/${itemsTotalCount} labels — `}</span>
          <span>
            {secondsLeft < 5 && progressPercentage !== 0
              ? 'Finishing...'
              : secondsToString(secondsLeft)}
          </span>
        </Styled.ProcessSubtitleWrapper>
        <Styled.ProcessHint>
          It’s safe to close y’er browser or start another batch!
        </Styled.ProcessHint>
      </Styled.ProcessWrapper>
      <Styled.Ocean animate={oceanAnimationControls}>
        <Styled.LoadingWrapper>
          <Styled.CloudsWrapper>
            <Styled.Clouds>
              <Styled.Cloud1 animate={cloud1AnimationControls} src={loadingIcons.cloud1} />
              <Styled.Cloud2 animate={cloud2AnimationControls} src={loadingIcons.cloud2} />
              <Styled.Cloud3 animate={cloud3AnimationControls} src={loadingIcons.cloud1} />
            </Styled.Clouds>
          </Styled.CloudsWrapper>
          <Styled.Main>
            <Styled.AnimationContainer>
              <Styled.Box1 animate={box1AnimationControls} src={loadingIcons.box1} />
              <Styled.Box2 animate={box2AnimationControls} src={loadingIcons.box2} />
              <Styled.Box3 animate={box3AnimationControls} src={loadingIcons.box3} />
            </Styled.AnimationContainer>
          </Styled.Main>
          <Styled.Content>
            <Styled.AnimationContainer>
              <Styled.TalkingWrapper
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <Styled.TalkingBubble>
                  <Styled.TalkingText
                  // animate={questionAnimationControls}
                  >
                    {joke.question}
                  </Styled.TalkingText>
                  <Styled.Fish1 animate={fish2AnimationControls} src={loadingIcons.fish1} />
                </Styled.TalkingBubble>
              </Styled.TalkingWrapper>
              <Styled.TalkingWrapper
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <Styled.TalkingBubble right>
                  <Styled.TalkingText
                  //  animate={answerAnimationControls}
                  >
                    {joke.answer}
                  </Styled.TalkingText>
                  <Styled.Fish2 animate={fish2AnimationControls} src={loadingIcons.fish2} />
                </Styled.TalkingBubble>
              </Styled.TalkingWrapper>
              <Styled.Diver animate={diverAnimationControls} src={loadingIcons.diver} />
              <Styled.Fish3 animate={fish3AnimationControls} src={loadingIcons.fish3} />
              <Styled.Bubble1 animate={bubble1AnimationControls} src={loadingIcons.bubble1} />
              <Styled.Bubble2 animate={bubble2AnimationControls} src={loadingIcons.bubble2} />
              <Styled.Bubble3 animate={bubble3AnimationControls} src={loadingIcons.bubble3} />
              <Styled.Bubble4 animate={bubble4AnimationControls} src={loadingIcons.bubble1} />
              <Styled.Bubble5 animate={bubble5AnimationControls} src={loadingIcons.bubble2} />
              <Styled.Bubble6 animate={bubble6AnimationControls} src={loadingIcons.bubble3} />
            </Styled.AnimationContainer>
          </Styled.Content>
          <Styled.FooterWrapper />
        </Styled.LoadingWrapper>
      </Styled.Ocean>
    </Styled.Wrapper>
  );
}
