import { FieldArray, getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';
import Checkbox from '../form/Checkbox';
import DropdownSelect from '../form/DropdownSelect';
import FormControl from '../form/FormControl';
import Label from '../form/Label';
import TextField from '../form/TextField';
import IntercomArticleLink from '../IntercomArticleLink';
import { Col, Row } from '../layout/Grid';
import {
  CONTENT_TYPE_OPTIONS,
  INITIAL_CUSTOMS_FORM_SUBFORM_VALUES,
} from './customsFormSubformUtils';
import CustomsLineItemSubform, { CustomsLineItemSubformValues } from './CustomsLineItemSubform';
import { INITIAL_CUSTOMS_LINE_ITEM_SUBFORM_VALUES } from './customsLineItemSubformUtils';
import { PackageSubformValues } from './PackageSubform';
import { NamespacedSubform } from './types';

export type CustomsFormSubformProps<NS extends string> = NamespacedSubform<NS> & {
  required?: boolean;
};

export type CustomsFormSubformValues = {
  customsFormEnabled: boolean; // preset might have customs forms enabled
  customsSigner: string;
  customsContentType: string;
  customsItems: Array<CustomsLineItemSubformValues>;
  internationalTaxId: string;
};

export default function CustomsFormSubform<NS extends string>({
  namespace,
  required,
}: CustomsFormSubformProps<NS>) {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  const { customsFormEnabled, customsItems, customsContentType } = getIn(
    values,
    namespace,
  ) as CustomsFormSubformValues;

  // this form is dependent on there being a sibling package subform, from which we get the weightOunces and weightPounds keys
  // it autofills the customs form with values from the package details
  const { weightOunces, weightPounds } = getIn(values, 'package') as PackageSubformValues;

  useEffect(() => {
    if (
      customsFormEnabled &&
      customsItems[0].weightOunces === '' &&
      customsItems[0].weightPounds === ''
    ) {
      setFieldValue(`${namespace}.customsItems.0.weightOunces`, weightOunces);
      setFieldValue(`${namespace}.customsItems.0.weightPounds`, weightPounds);
    }
    if (customsFormEnabled && customsContentType === '') {
      setFieldValue(
        `${namespace}.customsContentType`,
        INITIAL_CUSTOMS_FORM_SUBFORM_VALUES.customsContentType,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customsFormEnabled]);

  return (
    <Row>
      <Col md={12}>
        <FormControl
          name={`${namespace}.customsFormEnabled`}
          disabled={required}
          as={Checkbox}
          label="Customs Form"
          type="checkbox"
          secondaryText="Required for International, Military APO/FPO, and U.S. Territories"
        />
      </Col>
      <Col md={12} spaceBelow>
        {customsFormEnabled && (
          <Row>
            <Col md={12} spaceBelow>
              <FormControl
                name={`${namespace}.customsSigner`}
                as={TextField}
                label="Sign Customs Form As"
              />
            </Col>
            <Col md={12}>
              <Label>Package Content Type</Label>
            </Col>
            <Col md={12} spaceBelow>
              <FormControl
                name={`${namespace}.customsContentType`}
                as={DropdownSelect}
                options={CONTENT_TYPE_OPTIONS}
              />
            </Col>
            <Col md={12}>
              <FieldArray
                name={`${namespace}.customsItems`}
                render={(arrayHelpers) =>
                  customsItems.map((_item, index) => (
                    <CustomsLineItemSubform<`${NS}.${keyof CustomsFormSubformValues}.${number}`>
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      namespace={`${namespace}.customsItems.${index}`}
                      position={index + 1}
                      onAdd={() => {
                        arrayHelpers.push({
                          ...INITIAL_CUSTOMS_LINE_ITEM_SUBFORM_VALUES,
                        });
                      }}
                      onRemove={() => {
                        arrayHelpers.remove(index);
                      }}
                      removeEnabled={index > 0}
                    />
                  ))
                }
              />
            </Col>
            <Col md={12} spaceBelow>
              <Label
                secondary={
                  <>
                    Optional: Used for prepaid VAT taxes (IOSS / HMRC / VOEC / EORI){' '}
                    <IntercomArticleLink href="https://support.pirateship.com/en/articles/5313602-what-is-an-exporter-international-tax-id">
                      Learn more
                    </IntercomArticleLink>
                  </>
                }
              >
                International Tax ID
              </Label>
              <FormControl
                as={TextField}
                name={`${namespace}.internationalTaxId`}
                label="International Tax ID"
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
