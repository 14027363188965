import IntercomArticleLink from './IntercomArticleLink';
import QuestionIconTooltip from './QuestionIconTooltip';

type LinkedIconTooltipProps = React.PropsWithChildren<{
  href: string;
}>;

export default function LinkedIconTooltip({ href, children }: LinkedIconTooltipProps) {
  return (
    <IntercomArticleLink href={href}>
      <QuestionIconTooltip>{children}</QuestionIconTooltip>
    </IntercomArticleLink>
  );
}
