import Select, { SelectProps } from './Select';

export type CountrySelectProps = Omit<SelectProps, 'options'> & {
  options?: ReadonlyArray<{
    countryCode: string;
    name: string;
  }>;
};

export default function CountrySelect({
  options,
  placeholder = 'Country',
  ...others
}: CountrySelectProps) {
  return (
    <Select {...others} placeholder={placeholder}>
      {options &&
        options.map(({ countryCode, name }) => (
          <option key={countryCode} value={countryCode}>
            {name}
          </option>
        ))}
    </Select>
  );
}
