import * as yup from 'yup';
import { ExtraServicesSubformValues } from './ExtraServicesSubform';

export const INITIAL_EXTRA_SERVICE_SUBFORM_VALUES: ExtraServicesSubformValues = {
  deliveryConfirmationSelectEnabled: false,
  deliveryConfirmation: 'signature',
  returnLabelSelectEnabled: false,
  returnLabel: 'return',
  insuranceInputEnabled: false,
  insuredValue: '',
  isMediaMail: false,
  isIrregularPackage: false,
};

export const extraServicesValidationSchema = () =>
  yup.object<ExtraServicesSubformValues>({
    deliveryConfirmationSelectEnabled: yup.boolean().defined(),
    deliveryConfirmation: yup
      .mixed<ExtraServicesSubformValues['deliveryConfirmation']>()
      .when(
        'deliveryConfirmationEnabled',
        (deliveryConfirmationEnabled: boolean, schema: yup.StringSchema) =>
          deliveryConfirmationEnabled ? schema.required() : schema,
      ),
    returnLabelSelectEnabled: yup.boolean().defined(),
    returnLabel: yup
      .mixed<ExtraServicesSubformValues['returnLabel']>()
      .when('returnLabelEnabled', (returnLabelEnabled: boolean, schema: yup.StringSchema) =>
        returnLabelEnabled ? schema.required() : schema,
      ),
    insuranceInputEnabled: yup.boolean().defined(),
    insuredValue: yup
      .number()
      .when('insuranceEnabled', (insuranceEnabled: boolean, schema: yup.StringSchema) =>
        insuranceEnabled ? schema.required().max(10000) : schema,
      ),
    isMediaMail: yup.boolean().defined(),
    isIrregularPackage: yup.boolean().defined(),
  });
